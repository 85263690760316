
import Vue from 'vue';
import OfferingCard from '@/components/OfferingCard.vue';
import ComplexBackgroundBig from '@/components/designElements/ComplexBackgroundBig.vue';
import { ROUTER } from '@/router/constants';
import { RUNNIT_NODE_DEF_TOOL_TYPE } from '@/constants/enums';
import WhiteTransparentChip from '@/components/WhiteTransparentChip.vue';

export default Vue.extend({
	name: 'RunnitsPromoCard',
	props: {
		showButton: { type: Boolean, default: false },
		showAnimation: { type: Boolean, default: false },
	},
	data () {
		return {
			runnits: [
				{
					id: 2,
					title: 'Runnit Apex',
					src: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Frd-webapp%2Frunnit-node-hero-images%2Fapex-hero-image.png?alt=media&token=294a8828-968d-4e76-9a3f-20fac8f2e6b5',
					description: 'Runnit Apex is our best pipeline that offers the most cutting edge',
					appType: RUNNIT_NODE_DEF_TOOL_TYPE.CURATED,
				},
				{
					id: 3,
					title: 'FLUX [Dev]',
					src: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Frd-webapp%2Frunnit-node-hero-images%2Fflux-dev-hero.jpeg?alt=media&token=1ac5f484-89da-4b39-ac00-479203c95514',
					description: `FLUX.1 [Dev] by BlackForestLabs`,
					appType: RUNNIT_NODE_DEF_TOOL_TYPE.CURATED,
				},
				{
					id: 1,
					title: 'Ideogram V2',
					src: 'https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Frd-webapp%2Frunnit-node-hero-images%2Fideogram_v2_hero.png?alt=media&token=d7d60de5-1893-43d2-8798-669c0ec4fe23',
					description: 'V2 of the Ideogram.ai pipeline!',
					appType: RUNNIT_NODE_DEF_TOOL_TYPE.CORE,
				},
			],
			numGridItems: 16,
		};
	},
	computed: {
		computedTransform () {
			let rotateX = 53;
			let rotateY = 356;
			let rotateZ = 31;
			let scale = 0.9;
			let translateY = -220;
			let translateX = 50;

			if (this.$vuetify.breakpoint.xsOnly) {
				rotateZ = 44;
				scale = 0.35;
				translateY = -1570;
				translateX = 438;
			} else if (this.$vuetify.breakpoint.smOnly) {
				rotateZ = 40;
				scale = 0.45;
				translateY = -970;
				translateX = 260;
			} else if (this.$vuetify.breakpoint.mdOnly) {
				rotateZ = 41;
				scale = 0.65;
				translateY = -468;
				translateX = 150;
			} else if (this.$vuetify.breakpoint.xlOnly) {
				rotateZ = 21;
				scale = 1.1;
				translateY = -120;
				translateX = -100;
			}

			return `rotateX(${rotateX}deg) rotateY(${rotateY}deg) rotateZ(${rotateZ}deg) scale(${scale}) translateY(${translateY}px) translateX(${translateX}px)`;
		},
	},
	methods: {
		goToRunnitsHome () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_HOME });
		},
	},
	components: {
		WhiteTransparentChip,
		OfferingCard,
		ComplexBackgroundBig,
	},
});
