
import Vue from 'vue';
import { RUNNIT_NODE_DEF_TOOL_TYPE } from '@/constants/enums';
import GlassButton from '@/components/base/GlassButton.vue';
import RunnitImage from '@/views/Runnits/RunnitImage.vue';

export default Vue.extend({
	name: 'OfferingCard',
	props: {
		disabled: { type: Boolean, default: false },
		selectIcon: { type: String, default: 'mdi-check-circle-outline' },
		selectText: { type: String, default: 'Select' },
		showEditBtn: { type: Boolean, default: false },
		editText: { type: String, default: 'Edit' },
		disabledText: { type: String, default: null },
		disabledCtaText: { type: String, default: null },
		disabledCtaProps: { type: Object, default: () => ({}) },
		title: { type: String, default: '' },
		imageUrl: { type: String, default: '' },
		avatar: { type: [Object, String], default: null },
		description: { type: String, default: '' },
		displayOnly: { type: Boolean, default: false },
		horizontal: { type: Boolean, default: false },
		publishedAt: { type: Object, default: null },
		showNotPublished: { type: Boolean, default: false },
		small: { type: Boolean, default: false },
		toolType: { type: String, required: true },
		isFeatured: { type: Boolean, required: false },
	},
	data () {
		return {
			RUNNIT_NODE_DEF_TOOL_TYPE,
		};
	},
	computed: {
		vertical () {
			return !this.horizontal;
		},
		computedContainerWidth () {
			let width: number = 300;

			if (this.vertical) {
				width = 300

				if (this.small) {
					width = 190;
				}
			}

			if (this.horizontal) {
				width = 460

				if (this.small) {
					width = 320;
				}
			}

			return width;
		},
		computedContainerHeight () {
			let height: number = 415;

			if (this.vertical) {
				height = 415

				if (this.small) {
					height = 300;
				}
			}

			if (this.horizontal) {
				height = 250

				if (this.small) {
					height = 150;
				}
			}

			return height;
		},
		computedImageHeightWidth () {
			let heightWidth: number = 260;

			if (this.vertical) {
				heightWidth = 260

				if (this.small) {
					heightWidth = 150;
				}
			}

			if (this.horizontal) {
				heightWidth = 200

				if (this.small) {
					heightWidth = 120;
				}
			}

			return heightWidth;
		},
		computedDescriptionCharLimit () {
			return this.small ? 77 : 70;
		},
		offeringCardStyles () {
			let styles = {
				background: 'linear-gradient(180deg, var(--grey-825), var(--grey-835))',
			};

			if (this.toolType === RUNNIT_NODE_DEF_TOOL_TYPE.TEAM) {
				styles.background = 'var(--blue-grey-800)';
				styles['backgroundImage'] = 'radial-gradient(circle at -30% 0, var(--blue-grey-500), rgba(255, 255, 255, 0) 70%)';
			}

			return styles;
		},
		descriptionStyles () {
			const styles = {
				color: 'rgba(255, 255, 255, 0.6)',
			};

			if (this.toolType === RUNNIT_NODE_DEF_TOOL_TYPE.TEAM) {
				styles.color = 'var(--blue-grey-200)';
			}

			return styles;
		},
	},
	components: {
		GlassButton,
		RunnitImage,
	},
});
