
import { getRunnitNodeRunResultFilePath, RunnitNodeRunResult } from '@run-diffusion/shared';
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { storage } from '@/firebase';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';
import { NODE_RUN_RESULT_SELECTION_MODE } from './constants';
import _findIndex from 'lodash/findIndex';
import { RUNNIT_NODE_RUN_RESULT_TYPE } from '@/constants/enums';

export default Vue.extend({
	name: 'RunnitVideo',
	props: {
		nodeRunResult: { type: Object, default: null },
		isThumb: { type: Boolean, default: false },
		noClick: { type: Boolean, default: false },
	},
	mixins: [
		RunnitsImageSelectMixin,
	],
	data () {
		return {
			showGif: false,
			thumbSrc: null,
			thumbError: false,
			gifSrc: null,
			vidSrc: null,
			srcIsFetching: false,
			srcIsError: false,
			srcIsLoaded: false,
		};
	},
	computed: {
		...mapState([
		]),
		isSelectionMode () {
			return !!(!this.noClick && this.currentSelectionState.nodeRunResultSelectionMode && this.currentSelectionState.canSelectTypes.includes(RUNNIT_NODE_RUN_RESULT_TYPE.VID));
		},
		isSingleSelection () {
			return this.currentSelectionState.nodeRunResultSelectionMode === NODE_RUN_RESULT_SELECTION_MODE.SINGLE;
		},
		isMultiSelection () {
			return this.currentSelectionState.nodeRunResultSelectionMode === NODE_RUN_RESULT_SELECTION_MODE.MULTIPLE;
		},
		isSelected () {
			return !!(
				this.currentSelectionState.selectedNodeRunResults.length &&
				this.nodeRunResult &&
				_findIndex(this.currentSelectionState.selectedNodeRunResults, (n: RunnitNodeRunResult) => (n.uuid === this.nodeRunResult.uuid)) !== -1
			);
		},
	},
	watch: {
		nodeRunResult: {
			immediate: true,
			async handler (newVal: RunnitNodeRunResult, oldVal: RunnitNodeRunResult) {
				const newFileName: string = this._get(newVal, 'file.name') || null;
				const oldFileName: string = this._get(oldVal, 'file.name') || null;
				if (newVal && newFileName !== oldFileName) {
					await this.fetchSrc(newVal);
				}
			},
		},
		vidSrc: {
			immediate: true,
			handler (newVal: string, oldVal: string) {
				if (newVal && newVal !== oldVal) {
					if (this.$refs.videoPlayer) {
						this.$refs.videoPlayer.load();
					}
				}
			}
		}
	},
	methods: {
		...mapActions([
		]),
		async retryLoopFetchSrc (filePath: string, numTries: number, srcType: string) {
			const fileRef = storage.ref(filePath);
			let count: number = 1;
			while (true) {
				try {
					this[srcType] = await fileRef.getDownloadURL();
					break;
				} catch (e) {
					if (count >= numTries) throw e;

					count++;
					await new Promise(resolve => setTimeout(resolve, 250)); // wait before next retry
				}
			}
		},
		async fetchSrc (input: RunnitNodeRunResult) {
			const nodeRunResult: RunnitNodeRunResult = input as RunnitNodeRunResult;

			const { filePath, thumbFilePath, gifFilePath } = getRunnitNodeRunResultFilePath(nodeRunResult);

			if (
				nodeRunResult &&
				nodeRunResult.file &&
				nodeRunResult.file.name
			) {
				try {
					this.srcIsFetching = true;
					this.srcIsError = false;
					this.srcIsLoaded = false;

					if (this.isThumb) {
						try {
							await Promise.all([
								this.retryLoopFetchSrc(thumbFilePath, 1, 'thumbSrc'),
								this.retryLoopFetchSrc(gifFilePath, 1, 'gifSrc')
							]);
						} catch (e) {
							// The thumb img wasn't created yet, so just default to the original
							await this.retryLoopFetchSrc(filePath, 40, 'vidSrc');
						}
					} else {
						await this.retryLoopFetchSrc(filePath, 40, 'vidSrc');
					}
				} catch (e) {
					console.error(e);
					this.srcIsError = true;
				} finally {
					this.srcIsFetching = false;
				}
			}
		},
		onClick () {
			if (this.noClick) return;

			console.log('onCllick', {
				selectionMode: this.isSelectionMode,
				singleSelect: this.isSingleSelection,
				multiSelect: this.isMultiSelection,
			})
			if (this.isSelectionMode) {
				// TODO the value emitted from this isn't actually used.
				if (this.isSingleSelection) {
					this.$emit('on-single-select', {
						nodeRunResult: this.nodeRunResult,
						upload: this.upload,
						avatar: this.avatar,
					});
				} else if (this.isMultiSelection) {
					this.$emit('on-multi-select', {
						nodeRunResult: this.nodeRunResult,
						upload: this.upload,
						avatar: this.avatar,
					});
				}
			} else {
				this.$emit('on-click');
			}
		},
		handleThumbError () {
			this.thumbError = true;
		}
	},
	components: {
	},
});
