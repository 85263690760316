
import Vue from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';
import GlassButton from '@/components/base/GlassButton.vue';
import TokenClubAddOnSelect from '@/views/Runnits/pages/RunnitsSubscriptionsPage/TokenClubAddOnSelect/TokenClubAddOnSelect.vue';
import LimitClubAddOnSelect from '@/views/Runnits/pages/RunnitsSubscriptionsPage/LimitClubAddOnSelect/LimitClubAddOnSelect.vue';
import {
	ClubKey,
	ClubOffer,
	getCurrentUserLimitClubAddOn,
	getCurrentUserTokenClubAddOn,
	LimitClubAddOn,
	TokenClubAddOn,
	User,
} from '@run-diffusion/shared';
import { db } from '@/firebase';
import { buildNewVuefireBindingArray, get$bindFirestoreOptions } from '@/mixins';
import _toString from 'lodash/toString';
import _sortBy from 'lodash/sortBy';
import _mapKeys from 'lodash/mapKeys';
import _isEmpty from 'lodash/isEmpty';
import { mapState } from 'vuex';
import StaticBenefitLineItem from '@/views/Runnits/pages/RunnitsSubscriptionsPage/StaticBenefitLineItem.vue';
import { SHARED_STORAGE_CAP_BYTES } from '@/components/StorageCapCard/utils';

export default Vue.extend({
	name: 'RunnitsSubscriptionCard',
	props: {
		clubOffer: { type: Object, required: true },
		showCcOptions: { type: Boolean, required: true },
		isMonthly: { type: Boolean, required: true },
	},
	data () {
		return {
			SHARED_STORAGE_CAP_BYTES,
			loadingAddOns: false,
			vuefireLimitClubAddOns: [],
			vuefireTokenClubAddOns: [],
			limitClubAddOns: [],
			tokenClubAddOns: [],
			limitClubAddOnsMap: {},
			tokenClubAddOnsMap: {},
			selectedLimitClubAddOn: null,
			selectedTokenClubAddOn: null,
			currentPlanLimitClubAddOn: null,
			currentPlanTokenClubAddOn: null,
		};
	},
	computed: {
		...mapState([
			'user',
		]),
		isMatchingClub () {
			const club: ClubKey = this._get(this.user, 'club') || null;
			return !!(club && this.clubOffer.club === club);
		},
		isMatchingAnnual () {
			if (this.clubOffer.isFreemium) return true;
			const isAnnual: boolean = !!this._get(this.user, 'clubInfo.isAnnual');
			return !this.isMonthly === isAnnual;
		},
		isMatchingLimitClubAddOn () {
			const limitClubAddOnId: string = this._get(this.user, 'clubInfo.limitId') || null;
			if (!limitClubAddOnId && this.clubOffer.isFreemium) return true;
			return !!(limitClubAddOnId && this.selectedLimitClubAddOn && limitClubAddOnId === this.selectedLimitClubAddOn.id);
		},
		isMatchingTokenClubAddOn () {
			const tokenClubAddOnId: string = this._get(this.user, 'clubInfo.tokenId') || null;
			if (!tokenClubAddOnId && this.clubOffer.isFreemium) return true;
			return !!(tokenClubAddOnId && this.selectedTokenClubAddOn && tokenClubAddOnId === this.selectedTokenClubAddOn.id);
		},
		isMatchingEverything () {
			return !!(
				this.isMatchingClub &&
				this.isMatchingAnnual &&
				this.isMatchingLimitClubAddOn &&
				this.isMatchingTokenClubAddOn
			);
		},
		btnText () {
			if (this.isMatchingClub) return 'Update Plan';
			if (this.clubOffer.isFreemium && !this._get(this.user, 'clubOffer.isFreemium')) return 'Downgrade Plan';
			return 'Choose Plan';
		},
		hasAddOns () {
			return !!(
				this.hasTokenClubAddOns(this.clubOffer, this.tokenClubAddOns) &&
				this.hasLimitClubAddOns(this.clubOffer, this.limitClubAddOns)
			);
		},
		ctaDisabled () {
			return !!(
				this.isMatchingEverything ||
				(
					!this.selectedTokenClubAddOn &&
					this.hasTokenClubAddOns(this.clubOffer, this.tokenClubAddOns)
				) ||
				(
					!this.selectedLimitClubAddOn &&
					this.hasLimitClubAddOns(this.clubOffer, this.limitClubAddOns)
				)
			);
		},
		monthlyPriceAmount () {
			const subscriptionAmount: number = this._get(this.clubOffer, 'stripe.monthlyPriceAmount') || 0;
			const tokenClubAddOnAmount: number = (this.tokenClubAddOns || [])
				.reduce((acc: number, tokenClubAddOn: TokenClubAddOn) => {
					if (this.selectedTokenClubAddOn && tokenClubAddOn.id === this.selectedTokenClubAddOn.id) {
						return acc + this._get(tokenClubAddOn, 'stripe.monthlyPriceAmount') || 0;
					}
					return acc;
				}, 0);
			const limitClubAddOnAmount: number = (this.limitClubAddOns || [])
				.reduce((acc: number, limitClubAddOn: LimitClubAddOn) => {
					if (this.selectedLimitClubAddOn && limitClubAddOn.id === this.selectedLimitClubAddOn.id) {
						return acc + this._get(limitClubAddOn, 'stripe.monthlyPriceAmount') || 0;
					}
					return acc;
				}, 0);
			return subscriptionAmount +
				tokenClubAddOnAmount +
				limitClubAddOnAmount;
		},
		yearlyPriceAmount () {
			const subscriptionAmount: number = this._get(this.clubOffer, 'stripe.yearlyPriceAmount') || 0;
			const tokenClubAddOnAmount: number = (this.tokenClubAddOns || [])
				.reduce((acc: number, tokenClubAddOn: TokenClubAddOn) => {
					if (this.selectedTokenClubAddOn && tokenClubAddOn.id === this.selectedTokenClubAddOn.id) {
						return acc + this._get(tokenClubAddOn, 'stripe.yearlyPriceAmount') || 0;
					}
					return acc;
				}, 0);
			const limitClubAddOnAmount: number = (this.limitClubAddOns || [])
				.reduce((acc: number, limitClubAddOn: LimitClubAddOn) => {
					if (this.selectedLimitClubAddOn && limitClubAddOn.id === this.selectedLimitClubAddOn.id) {
						return acc + this._get(limitClubAddOn, 'stripe.yearlyPriceAmount') || 0;
					}
					return acc;
				}, 0);
			return subscriptionAmount +
				tokenClubAddOnAmount +
				limitClubAddOnAmount;
		},
		clubOfferPriceData () {
			const amount: number = this.isMonthly ? this.monthlyPriceAmount : this.yearlyPriceAmount;
			const amountInMonths: number = this.isMonthly ? amount : Math.round(amount / 12);

			const getCentsStr: Function = (amt: number): string => {
				const cents: number = amt % 100;
				return cents === 0 ? '' : _toString(cents).padStart(2, '0');
			};
			return {
				amountInMonths,
				amountInMonthsDollars: Math.floor(amountInMonths / 100),
				amountInMonthsCentsStr: getCentsStr(amountInMonths),
				monthlyAmount: this.monthlyPriceAmount,
				monthlyDollars: Math.floor(this.monthlyPriceAmount / 100),
				monthlyCentsStr: getCentsStr(this.monthlyPriceAmount),
				yearlyAmount: this.yearlyPriceAmount,
				yearlyDollars: Math.floor(this.yearlyPriceAmount / 100),
				yearlyCentsStr: getCentsStr(this.yearlyPriceAmount),
				yearlyDiscountPercentOff: Math.round(((this.monthlyPriceAmount - amountInMonths) / (this.monthlyPriceAmount || 1)) * 100),
			};
		},
	},
	watch: {
		clubOffer: {
			immediate: true,
			async handler (newVal: ClubOffer, oldVal: ClubOffer) {
				if (newVal && newVal !== oldVal) {
					try {
						this.loadingAddOns = true;
						await Promise.all([
							(async () => {
								const query = db.collection(`clubOffers/${newVal.id}/tokenClubAddOns`)
									.where('publishedAt', '!=', null);
								const newResult: TokenClubAddOn[] = await this.$bind(
									'vuefireTokenClubAddOns',
									query,
									get$bindFirestoreOptions(),
								);
								if (!newResult) this.resultTokenClubAddOns(newResult);
							})(),
							(async () => {
								const query = db.collection(`clubOffers/${newVal.id}/limitClubAddOns`)
									.where('publishedAt', '!=', null);
								const newResult: LimitClubAddOn[] = await this.$bind(
									'vuefireLimitClubAddOns',
									query,
									get$bindFirestoreOptions(),
								);
								if (!newResult) this.resultLimitClubAddOns(newResult);
							})(),
						]);
					} catch (e) {
						console.error(e);
					} finally {
						this.loadingAddOns = false;
					}
				}
			},
		},
		vuefireTokenClubAddOns: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: TokenClubAddOn[]) {
				this.resultTokenClubAddOns(newVal);
			},
		},
		vuefireLimitClubAddOns: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: LimitClubAddOn[]) {
				this.resultLimitClubAddOns(newVal);
			},
		},
		tokenClubAddOns: {
			immediate: true,
			handler (newVal: TokenClubAddOn[], oldVal: TokenClubAddOn[]) {
				this.currentPlanTokenClubAddOn = getCurrentUserTokenClubAddOn(this.user, newVal, false);
				if (
					_isEmpty(newVal) ||
					(
						newVal !== oldVal &&
						this.selectedTokenClubAddOn &&
						!newVal.some(({ id }) => id === this.selectedTokenClubAddOn.id)
					)
				) {
					this.selectedTokenClubAddOn = null;
				}
				if (!this.selectedTokenClubAddOn && !_isEmpty(newVal) && newVal !== oldVal) {
					// Set default selections
					let defaultTokenClubAddOn: TokenClubAddOn = this.currentPlanTokenClubAddOn;
					if (!defaultTokenClubAddOn) defaultTokenClubAddOn = getCurrentUserTokenClubAddOn(this.user, newVal, true);
					if (defaultTokenClubAddOn) this.selectedTokenClubAddOn = defaultTokenClubAddOn;
				}
			},
		},
		limitClubAddOns: {
			immediate: true,
			handler (newVal: LimitClubAddOn[], oldVal: LimitClubAddOn[]) {
				this.currentPlanLimitClubAddOn = getCurrentUserLimitClubAddOn(this.user, newVal, false);
				if (
					_isEmpty(newVal) ||
					(
						newVal !== oldVal &&
						this.selectedLimitClubAddOn &&
						!newVal.some(({ id }) => id === this.selectedLimitClubAddOn.id)
					)
				) {
					this.selectedLimitClubAddOn = null;
				}
				if (!this.selectedLimitClubAddOn && !_isEmpty(newVal) && newVal !== oldVal) {
					// Set default selections
					let defaultLimitClubAddOn: LimitClubAddOn = this.currentPlanLimitClubAddOn;
					if (!defaultLimitClubAddOn) defaultLimitClubAddOn = getCurrentUserLimitClubAddOn(this.user, newVal, true);
					if (defaultLimitClubAddOn) this.selectedLimitClubAddOn = defaultLimitClubAddOn;
				}
			},
		},
		user: {
			immediate: true,
			handler (newVal: User, oldVal: User) {
				if (newVal && newVal !== oldVal) {
					this.currentPlanTokenClubAddOn = getCurrentUserTokenClubAddOn(newVal, this.tokenClubAddOns, false);
					this.currentPlanLimitClubAddOn = getCurrentUserLimitClubAddOn(newVal, this.limitClubAddOns, false);
				}
			},
		},
	},
	methods: {
		hasTokenClubAddOns (clubOffer: ClubOffer, tokenClubAddOns: TokenClubAddOn[]) {
			return !!(
				clubOffer &&
				tokenClubAddOns &&
				tokenClubAddOns.length &&
				clubOffer.benefits &&
				(clubOffer.benefits.monthlyRunnitTokens || clubOffer.benefits.dailyRunnitTokens)
			);
		},
		hasLimitClubAddOns (clubOffer: ClubOffer, limitClubAddOns: LimitClubAddOn[]) {
			return !!(
				clubOffer &&
				limitClubAddOns &&
				limitClubAddOns.length
			);
		},
		resultTokenClubAddOns (payload: TokenClubAddOn[]) {
			const newArrayPayload: TokenClubAddOn[] = buildNewVuefireBindingArray(
				_sortBy(payload || [], 'sortOrder'),
			);
			this.tokenClubAddOns = newArrayPayload;
			this.tokenClubAddOnsMap = _mapKeys(newArrayPayload, 'id');
		},
		resultLimitClubAddOns (payload: LimitClubAddOn[]) {
			const newArrayPayload: LimitClubAddOn[] = buildNewVuefireBindingArray(
				_sortBy(payload || [], 'sortOrder'),
			);
			this.limitClubAddOns = newArrayPayload;
			this.limitClubAddOnsMap = _mapKeys(newArrayPayload, 'id');
		},
		handleCtaClick () {
			this.$emit('cta-click', {
				clubOffer: this.clubOffer,
				tokenClubAddOn: this.selectedTokenClubAddOn,
				limitClubAddOn: this.selectedLimitClubAddOn,
			});
		},
	},
	components: {
		StaticBenefitLineItem,
		BaseButton,
		GlassButton,
		TokenClubAddOnSelect,
		LimitClubAddOnSelect,
	},
});
