import { render, staticRenderFns } from "./AddEditWorkspace.vue?vue&type=template&id=249e2420&scoped=true&"
import script from "./AddEditWorkspace.vue?vue&type=script&lang=ts&"
export * from "./AddEditWorkspace.vue?vue&type=script&lang=ts&"
import style0 from "./AddEditWorkspace.vue?vue&type=style&index=0&id=249e2420&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "249e2420",
  null
  
)

export default component.exports