
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import AddBalanceFundsButton from '@/components/AddBalanceFundsButton.vue';
import { MyBalanceMixin, TeamUserMixin } from '@/mixins';
import ListItem from '@/components/base/ListItem.vue';
import _truncate from 'lodash/truncate';
import _isEmpty from 'lodash/isEmpty';
import TeamPicker from '@/components/TeamPicker.vue';
import BorderCardUpsell from '@/components/BorderCardUpsell.vue';
import { UPSELL_TYPE } from '@/constants/constants';
import JoinOurDiscord from '@/components/Toolbar/JoinOurDiscord.vue';
import SessionsHistoryDialog from '@/components/Toolbar/UserAccount/SessionsHistoryDialog.vue';
import PaymentsHistoryDialog from '@/components/Toolbar/UserAccount/PaymentsHistoryDialog.vue';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'BalanceDetailsDialog',
	mixins: [
		MyBalanceMixin,
		TeamUserMixin,
	],
	props: {
		value: { type: Boolean, default: false },
		scrollToPrivateBalance: { type: Boolean, default: false },
		scrollToTeamBalance: { type: Boolean, default: false },
		showInsufficientTeamBalanceCallout: { type: Boolean, default: false },
	},
	data () {
		return {
			UPSELL_TYPE,
			sessionsHistoryDialogOpen: false,
			paymentsHistoryDialogOpen: false,
		};
	},
	computed: {
		...mapState([
			'realTimeMoment',
			'team',
			'user',
			'userBalanceAccount',
			'teamBalanceAccount',
		]),
		...mapGetters([
			'clubOfferBenefits',
		]),
	},
	watch: {
		value: {
			immediate: true,
			handler (newVal: boolean, oldVal: boolean) {
				if (newVal && newVal !== oldVal) {
					if (this.scrollToPrivateBalance) {
						setTimeout(() => {
							this.$scrollTo('#private-balance-details-scroll-anchor', 800, {
								container: '#balance-details-dialog-scroll-container',
							});
						}, 100);
					} else if (this.scrollToTeamBalance) {
						setTimeout(() => {
							this.$scrollTo('#team-balance-details-scroll-anchor', 800, {
								container: '#balance-details-dialog-scroll-container',
								offset: -15,
							});
						}, 100);
					}
				}
			},
		},
	},
	methods: {
		...mapActions([
			'setUpsellDialog',
		]),
		_isEmpty,
		_truncate,
		onInput (val: boolean) {
			this.$emit('input', !!val);
		},
		doUpsellToCreatorClub () {
			this.setUpsellDialog({ creatorsClub: true });
		},
		doUpsellToTeamPlan () {
			window.open(this.$router.resolve({ name: ROUTER.TEAM_MEMBERS }).href, '_blank');
		},
	},
	components: {
		SessionsHistoryDialog,
		PaymentsHistoryDialog,
		JoinOurDiscord,
		BorderCardUpsell,
		TeamPicker,
		ListItem,
		AddBalanceFundsButton,
	},
});
