import {
	AnnouncementType,
	AppKey,
	ClubKey,
	ExtensionOfferType,
	HardwareKey,
	RunnitNodeFieldType,
	RunnitNodeDefFieldMapBuilderValueType,
	RunnitNodeFieldsSource,
	RunnitNodeStaticFieldsSource,
	SessionBackgroundTaskType,
	SessionBackgroundTaskStatus,
	SessionState,
	SessionType,
	SoftwareKey,
	StopCode,
	StorageOption,
	SurveyKey,
	ReleaseStatus,
	AppOfferType,
	AppOfferResourceType,
	WorkshopSessionQueueItemAdmission,
	RunnitNodeRunState,
	RunnitNodeRunMode,
	RunnitNodeStaticFieldsKey,
	UploadFileType,
	RunnitNodeDefProvider,
	RunnitNodeDefToolType,
	RunnitsAccessLevel,
	RunnitNodeDefPricingType,
	AvatarUseCase,
	RunnitMaskUseCase,
	RunnitNodeRunResultType,
} from '@run-diffusion/shared';
import { ImageUploadMode, RunnitType } from '@run-diffusion/shared/src';

export const CLUB_KEY: Record<string, ClubKey> = {
	CC_001: 'CC-001',
	CC_002: 'CC-002',
	FREE_001: 'FREE-001',
	RUNHOB_001: 'RUNHOB-001',
	RUNPRO_001: 'RUNPRO-001',
};

export const EXTENSION_OFFER_TYPE: Record<string, ExtensionOfferType> = {
	TEXT: 'TEXT',
	TOGGLE: 'TOGGLE',
};

export const APP_KEY: Record<string, AppKey> = {
	FILE_001: 'FILE-001',
	AUTO_001: 'AUTO-001',
	INVK_001: 'INVK-001',
	VLAD_001: 'VLAD-001',
	CMFY_001: 'CMFY-001',
	LINX_001: 'LINX-001',
	ACFT_001: 'ACFT-001',
	ADUI_001: 'ADUI-001',
	EZSD_001: 'EZSD-001',
	FCUS_001: 'FCUS-001',
	FCUS_API_001: 'FCUS-API-001',
	KOYA_001: 'KOYA-001',
	OOBA_001: 'OOBA-001',
	MKYO_001: 'MKYO-001',
	RTMY_001: 'RTMY-001',
	ENFG_001: 'ENFG-001',
	MANI_001: 'MANI-001',
};

export const HARDWARE_KEY: Record<string, HardwareKey> = {
	CPU_001: 'CPU-001',
	SM_001: 'SM-001',
	MD_001: 'MD-001',
	LG_001: 'LG-001',
	LG_002: 'LG-002',
	XL_001: 'XL-001',
};

export const SOFTWARE_KEY: Record<string, SoftwareKey> = {
	SD1_001: 'SD1-001',
	SD2_001: 'SD2-001',
	SDXL_001: 'SDXL-001',
	SDALL_001: 'SDALL-001',
	DB_001: 'DB-001',
};

export const SESSION_STATE: Record<SessionState, SessionState> = {
	PURCHASE: 'PURCHASE',
	INIT: 'INIT',
	BOOT: 'BOOT',
	READY: 'READY',
	EXTEND: 'EXTEND',
	STOP: 'STOP',
	RECONCILE: 'RECONCILE',
	STOPPED: 'STOPPED',
};

export const SESSION_TYPE: Record<SessionType, SessionType> = {
	RD: 'RD',
	SBUD_API: 'SBUD_API',
	INT_API_WORKER: 'INT_API_WORKER',
};

export const STOP_CODE: Record<StopCode, StopCode> = {
	MANUAL: 'MANUAL',
	SCHEDULED: 'SCHEDULED',
	FAILED_INIT: 'FAILED_INIT',
	FAILED_STOP: 'FAILED_STOP',
};

export const SESSION_BACKGROUND_TASK_TYPE: Record<SessionBackgroundTaskType, SessionBackgroundTaskType> = {
	DOWNLOAD_MODEL: 'DOWNLOAD_MODEL',
	ALERT: 'ALERT',
};

export const SESSION_BACKGROUND_TASK_STATUS: Record<SessionBackgroundTaskStatus, SessionBackgroundTaskStatus> = {
	IN_PROGRESS: 'IN_PROGRESS',
	DONE: 'DONE',
	ERROR: 'ERROR',
	INFO: 'INFO',
	WARNING: 'WARNING',
	DEBUG: 'DEBUG',
};

export const ANNOUNCEMENT_TYPE: Record<AnnouncementType, AnnouncementType> = {
	ALERT: 'ALERT',
	WARNING: 'WARNING',
	INFO: 'INFO',
	NEWS: 'NEWS',
	NO_NEW_SESSIONS: 'NO_NEW_SESSIONS',
};

export const STORAGE_OPTION: Record<StorageOption, StorageOption> = {
	PUBLIC: 'PUBLIC',
	PRIVATE: 'PRIVATE',
};

export const RELEASE_STATUS: Record<ReleaseStatus, ReleaseStatus> = {
	OLD: 'OLD',
	CURRENT: 'CURRENT',
	BETA: 'BETA',
};

export const APP_OFFER_TYPE: Record<AppOfferType, AppOfferType> = {
	IMAGE_GEN: 'IMAGE_GEN',
	IMAGE_TRAIN: 'IMAGE_TRAIN',
	AUDIO: 'AUDIO',
	FILE: 'FILE',
	LLM: 'LLM',
};

export const APP_OFFER_RESOURCE_TYPE: Record<AppOfferResourceType, AppOfferResourceType> = {
	YOUTUBE: 'YOUTUBE',
	LINK: 'LINK',
};

export const WORKSHOP_SESSION_QUEUE_ITEM_ADMISSION: Record<WorkshopSessionQueueItemAdmission, WorkshopSessionQueueItemAdmission> = {
	INIT: 'INIT',
	ACCEPT: 'ACCEPT',
};

export const SURVEY_KEY: Record<SurveyKey, SurveyKey> = {
	NEW_USER_1: 'NEW_USER_1',
	USER_USAGE_1: 'USER_USAGE_1',
	NEW_TEAM_1: 'NEW_TEAM_1',
};

export const RUNNITS_ACCESS_LEVEL: Record<RunnitsAccessLevel, RunnitsAccessLevel> = {
	PRIVATE: 'PRIVATE',
	SHARED: 'SHARED',
};

export const RUNNIT_TYPE: Record<RunnitType, RunnitType> = {
	WORKFLOW: 'WORKFLOW',
	SINGLE_TOOL: 'SINGLE_TOOL',
};

export type RunnitsPublishedState = 'PUBLISHED' | 'NOT_PUBLISHED';
export const RUNNITS_PUBLISHED_STATE: Record<RunnitsPublishedState, RunnitsPublishedState> = {
	PUBLISHED: 'PUBLISHED',
	NOT_PUBLISHED: 'NOT_PUBLISHED',
};

export const RUNNIT_NODE_FIELD_TYPE: Record<RunnitNodeFieldType, RunnitNodeFieldType> = {
	HEADER: 'HEADER',
	HTML: 'HTML',
	TEXT_AREA: 'TEXT_AREA',
	TEXT: 'TEXT',
	NUM: 'NUM',
	BOOLEAN: 'BOOLEAN',
	SINGLE_SELECT: 'SINGLE_SELECT',
	MULTI_SELECT: 'MULTI_SELECT',
	CHECKPOINT: 'CHECKPOINT',
	PROMPT: 'PROMPT',
	NEG_PROMPT: 'NEG_PROMPT',
	CFG: 'CFG',
	WIDTH_HEIGHT: 'WIDTH_HEIGHT',
	IMG: 'IMG',
	STEPS: 'STEPS',
	SEED: 'SEED',
	SLIDER: 'SLIDER',
};

export const RUNNIT_NODE_DEF_FIELD_MAP_BUILDER_VALUE_TYPE: Record<RunnitNodeDefFieldMapBuilderValueType, RunnitNodeDefFieldMapBuilderValueType> = {
	STR_MUSTACHE: 'STR_MUSTACHE',
};

export const RUNNIT_NODE_FIELDS_SOURCE: Record<RunnitNodeFieldsSource, RunnitNodeFieldsSource> = {
	DEF: 'DEF',
	NODE: 'NODE',
};

export const RUNNIT_NODE_STATIC_FIELDS_SOURCE: Record<RunnitNodeStaticFieldsSource, RunnitNodeStaticFieldsSource> = {
	DEF: 'DEF',
	NODE: 'NODE',
};

export const UPLOAD_FILE_TYPE: Record<UploadFileType, UploadFileType> = {
	IMG: 'IMG',
};

export const RUNNIT_NODE_RUN_RESULT_TYPE: Record<RunnitNodeRunResultType, RunnitNodeRunResultType> = {
	IMG: 'IMG',
	VID: 'VID',
}

export const RUNNIT_NODE_RUN_STATE: Record<RunnitNodeRunState, RunnitNodeRunState> = {
	DRAFT: 'DRAFT',
	INIT: 'INIT',
	NSFW: 'NSFW',
	QUEUED: 'QUEUED',
	ERROR: 'ERROR',
	DONE: 'DONE',
};

export const RUNNIT_NODE_RUN_MODE: Record<RunnitNodeRunMode, RunnitNodeRunMode> = {
	MANUAL: 'MANUAL',
};

export const RUNNIT_NODE_STATIC_FIELDS_KEY: Record<RunnitNodeStaticFieldsKey, RunnitNodeStaticFieldsKey> = {
	numResults: 'numResults',
};

export const RUNNIT_NODE_DEF_TOOL_TYPE: Record<RunnitNodeDefToolType, RunnitNodeDefToolType> = {
	CORE: 'CORE',
	CURATED: 'CURATED',
	USER_CREATED: 'USER_CREATED',
	TEAM: 'TEAM',
};

export const RUNNIT_NODE_DEF_PROVIDER: Record<RunnitNodeDefProvider, RunnitNodeDefProvider> = {
	RUNNIT_IMG: 'RUNNIT_IMG',
	RD: 'RD',
	OCTOAI: 'OCTOAI',
	RUNPOD: 'RUNPOD',
	IDEOGRAM: 'IDEOGRAM',
	FALAI: 'FALAI',
	NICHETENSOR: 'NICHETENSOR',
	RUNWAY: 'RUNWAY',
	LUMAAI: 'LUMAAI',
};

export const RUNNIT_NODE_DEF_PRICING_TYPE: Record<RunnitNodeDefPricingType, RunnitNodeDefPricingType> = {
	COST: 'COST',
	INFERENCE: 'INFERENCE',
};

export const AVATAR_USE_CASE: Record<AvatarUseCase, AvatarUseCase> = {
	RUNNIT: 'RUNNIT',
	RUNNIT_LIB: 'RUNNIT_LIB',
	RUNNIT_NODE_DEF: 'RUNNIT_NODE_DEF',
}

export const RUNNIT_MASK_USE_CASE: Record<RunnitMaskUseCase, RunnitMaskUseCase> = {
	INPAINTING: 'INPAINTING',
}

export const IMAGE_UPLOAD_MODE: Record<ImageUploadMode, ImageUploadMode> = {
	RUNNIT_UPLOAD: 'RUNNIT_UPLOAD',
	AVATAR: 'AVATAR',
}