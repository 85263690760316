
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RUNNIT_NODE_DEF_TOOL_TYPE } from '@/constants/enums';
import {
	RunnitsCRUDMixin,
	RunnitsAndRunnitNodeDefsBindingsMixin,
} from '@/mixins';
import OfferingCard from '@/components/OfferingCard.vue';
import { RunnitState } from '@/store';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import { ROUTER } from '@/router/constants';
import LoadingState from '@/components/states/LoadingState.vue';

export default Vue.extend({
	name: 'RunnitSharedPage',
	mixins: [
		RunnitsCRUDMixin,
		RunnitsAndRunnitNodeDefsBindingsMixin,
	],
	props: {},
	data () {
		return {
			RUNNIT_NODE_DEF_TOOL_TYPE,
		};
	},
	computed: {
		...mapState([
			'user',
			'runnits',
			'runnitsMap',
			'runnitState',
			'loadingRunnits',
		]),
		...mapGetters([
			'runnitsLimitMap',
		]),
	},
	watch: {
		runnitState: {
			immediate: true,
			handler (newVal: RunnitState, oldVal: RunnitState) {
				if (newVal !== oldVal && newVal.runnitsOwnerSelection !== RUNNITS_OWNER_SELECTION.TEAM) {
					// There is only "shared" runnits page for teams
					this.routerReplace(this.$route, this.$router, { name: ROUTER.RUNNITS_PRIVATE });
				}
			},
		},
	},
	methods: {
		...mapActions([
			'setUpsellDialog',
		]),
	},
	components: {
		LoadingState,
		OfferingCard,
	},
});
