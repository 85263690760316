import { render, staticRenderFns } from "./UpsellCreatorsClubDialog.vue?vue&type=template&id=0b484f28&"
import script from "./UpsellCreatorsClubDialog.vue?vue&type=script&lang=ts&"
export * from "./UpsellCreatorsClubDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports