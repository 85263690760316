
import Vue from 'vue';
import _find from 'lodash/find';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ROUTER } from '@/router/constants';
import { APP_BAR_HEIGHT } from '@/components/Toolbar/constants';
import { Announcement, Session } from '@run-diffusion/shared';
import { ToolbarState } from '@/store';
import { AwaitingServerUrlUi200Mixin } from '@/mixins';
import { ANNOUNCEMENT_TYPE, SESSION_STATE } from '@/constants/enums';
import {
	BOOTING_SESSION_STATES,
	NEW_USER_PROMO_END_DATE,
	NEW_USER_PROMO_START_DATE,
	READY_SESSION_STATES
} from '@/constants/constants';
import SessionInfoCard from '@/components/SessionInfoCard.vue';
import FileBrowserBtn from '@/components/Toolbar/FileBrowserBtn.vue';
import JoinOurDiscord from '@/components/Toolbar/JoinOurDiscord.vue';
import AccountMenuBtn from '@/components/Toolbar/AccountMenuBtn.vue';
import EnabledApiAnimation from '@/components/EnabledApiAnimation.vue';
import NotificationsBtn from '@/components/Toolbar/NotificationsBtn.vue';
import JoinCreatorsClub from '@/components/Toolbar/JoinCreatorsClubBtn.vue';
import SessionTimer from '@/components/Toolbar/SessionTimer/SessionTimer.vue';
import AdminSessionStats from '@/components/AdminStats/AdminSessionStats.vue';
import AnnouncementsBanner from '@/components/Toolbar/AnnouncementsBanner.vue';
import GetSupportBtn from '@/components/Toolbar/GetSupportBtn/GetSupportBtn.vue';
import FileBrowserNewTabBtn from '@/components/Toolbar/FileBrowserNewTabBtn.vue';
import SessionTimerBtns from '@/components/Toolbar/SessionTimer/SessionTimerBtns.vue';
import FailedSessionMessageDialogs from '@/components/FailedSessionMessageDialogs.vue';
import NewUserLoginPromoDialog from '@/components/PromoBanners/NewUserLoginPromoDialog.vue';
import RunnitAccountSelectionDialog from '@/views/Runnits/RunnitAccountSelectionDialog.vue';

export default Vue.extend({
	name: 'Toolbar',
	mixins: [
		AwaitingServerUrlUi200Mixin,
	],
	props: {
		allowFileBrowserSidePanel: { type: Boolean, default: false },
	},
	data () {
		return {
			APP_BAR_HEIGHT,
		};
	},
	computed: {
		...mapState([
			'isAdminProxyingUser',
			'user',
			'toolbar',
			'currSessions',
			'announcements',
		]),
		...mapGetters([
			'clubOfferBenefits',
		]),
		showAnnouncementsBanner () {
			return !!(
				this.user &&
				!this._get(this.$route, 'meta.isLoginPage')
			);
		},
		newUserPromotionIsLive () {
			const startDate = new Date(NEW_USER_PROMO_START_DATE);
			const endDate = new Date(NEW_USER_PROMO_END_DATE);
			const currentTime = new Date();

			return !!(
				this.user &&
				this.user.trial &&
				currentTime >= startDate &&
				currentTime <= endDate
			);
		},
		isRunnitsHomeRoute () {
			return this.$route.name === ROUTER.RUNNITS_HOME;
		},
		hasSessionRunning () {
			return this.currSessions.length;
		},
		isSessionBootingOrLaunched () {
			return !!(
				this.toolbar.session &&
				(
					READY_SESSION_STATES.includes(this.toolbar.session.state) ||
					BOOTING_SESSION_STATES.includes(this.toolbar.session.state)
				) &&
				this.$route.name === 'Launch'
			);
		},
		isSessionLaunched () {
			return !!(
				this.toolbar.session &&
				READY_SESSION_STATES.includes(this.toolbar.session.state) &&
				this.$route.name === 'Launch'
			);
		},
		isSessionApiEnabled () {
			return !!(
				this.toolbar.session &&
				this.toolbar.session.apiEnabled &&
				this.toolbar.session.serverUrlUi
			);
		},
		bannerAnnouncements () {
			return this.announcements.filter((announcement: Announcement) => (
				announcement.type !== ANNOUNCEMENT_TYPE.NO_NEW_SESSIONS
			));
		},
	},
	created () {
		this.syncToolbarSession(this.currSessions);
		this.bindAnnouncements();
	},
	watch: {
		currSessions (newVal: Session[]) {
			this.syncToolbarSession(newVal);
		},
		toolbar: {
			immediate: true,
			handler (newVal: ToolbarState, oldVal: ToolbarState) {
				if (
					newVal &&
					oldVal &&
					newVal.session &&
					oldVal.session &&
					READY_SESSION_STATES.includes(newVal.session.state) &&
					BOOTING_SESSION_STATES.includes(oldVal.session.state)
				) {
					this.$autoSoundMachine.playSessionStart(false);
					this.startAwaitingServerUrlUi200Loop(this.AWAITING_200);
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateToolbar',
			'bindAnnouncements',
		]),
		onLogoClick () {
			if (!this.$route.meta.isSessionPage) {
				this.routerPush(this.$route, this.$router, { name: 'Sessions' });
			}
		},
		onOpenSessionBar () {
			this.updateToolbar({
				sessionBarOpen: true,
			});
		},
		openMobileMenu () {
			console.log('openMobileMenu');
			this.updateToolbar({
				mobileMenuOpen: true,
			});
		},
		launchSession (session: Session) {
			if (session) {
				this.updateToolbar({
					session,
				});
				this.routerPush(this.$route, this.$router, {
					name: 'Launch',
					params: {
						sessionId: session.id,
					},
				});
			}
		},
		delaunchSession (routeName) {
			if (this.toolbar.session) {
				this.updateToolbar({
					session: null,
				});
				this.routerReplace(this.$route, this.$router, { name: routeName });
			}
		},
		syncToolbarSession (currSessions: Session[]) {
			if (!currSessions || !currSessions.length) {
				this.delaunchSession('Sessions');
				return;
			}

			const preventLaunchSession: boolean = !!this.$route.meta.preventLaunchSession;
			const isInternalAdminPage: boolean = !!this.$route.meta.isInternalAdminPage;
			const isOnAnotherSessionPage: boolean = this.$route.name === 'AnotherSession';
			const isOnSetupAnotherSessionPage: boolean = this.$route.name === 'SetupAnotherSession';
			const isOnLaunchPage: boolean = this.$route.name === 'Launch';
			const awaitSessionUuid: string = this._get(this.$route.query, 'awaitSession', null);
			const launchSessionId: string = this._get(this.$route.params, 'sessionId', null);

			if (isOnAnotherSessionPage || isOnSetupAnotherSessionPage) {
				const foundSession: Session = awaitSessionUuid ? _find(currSessions, ['uuid', awaitSessionUuid]) : null;
				if (foundSession) this.launchSession(foundSession);
			} else if (isOnLaunchPage) {
				const foundSession: Session = launchSessionId ? _find(currSessions, ['id', launchSessionId]) : null;
				if (foundSession) this.launchSession(foundSession);
				else this.delaunchSession('AnotherSession');
			} else {
				const foundSession: Session = launchSessionId ? _find(currSessions, ['id', launchSessionId]) : currSessions[0];
				if (
					foundSession &&
					!preventLaunchSession &&
					!isInternalAdminPage &&
					(
						this.$route.name === 'SetupSession' ||
						(
							this.$route.name !== 'Launch' &&
							foundSession.state === SESSION_STATE.READY &&
							confirm('You already have a session running, would you like to go to it now?')
						)
					)
				) {
					this.launchSession(foundSession);
				}
			}
		},
	},
	components: {
		SessionTimer,
		FileBrowserBtn,
		JoinOurDiscord,
		GetSupportBtn,
		AccountMenuBtn,
		SessionInfoCard,
		NotificationsBtn,
		JoinCreatorsClub,
		SessionTimerBtns,
		AdminSessionStats,
		AnnouncementsBanner,
		EnabledApiAnimation,
		FileBrowserNewTabBtn,
		NewUserLoginPromoDialog,
		FailedSessionMessageDialogs,
		RunnitAccountSelectionDialog,
	},
});
