import { render, staticRenderFns } from "./ImageInfoFields.vue?vue&type=template&id=5b86e094&scoped=true&"
import script from "./ImageInfoFields.vue?vue&type=script&lang=ts&"
export * from "./ImageInfoFields.vue?vue&type=script&lang=ts&"
import style0 from "./ImageInfoFields.vue?vue&type=style&index=0&id=5b86e094&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b86e094",
  null
  
)

export default component.exports