import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import { RunnitsOwnerSelection, RunnitState } from '@/store';
import { RunnitsAccessLevel, Team, User } from '@run-diffusion/shared';

export const RunnitsAndRunnitNodeDefsBindingsMixin = Vue.extend({
	computed: {
		...mapState([
			'user',
			'team',
			'runnitState',
		]),
	},
	watch: {
		runnitState: {
			immediate: true,
			handler (newVal: RunnitState, oldVal: RunnitState) {
				if (
					newVal &&
					oldVal &&
					(
						(
							newVal.runnitsOwnerSelection &&
							newVal.runnitsOwnerSelection !== oldVal.runnitsOwnerSelection
						) ||
						(
							newVal.runnitsAccessLevel &&
							newVal.runnitsAccessLevel !== oldVal.runnitsAccessLevel
						)
					)
				) {
					this.doRunnitsAndRunnitNodeDefsBindings(newVal.runnitsOwnerSelection, newVal.runnitsAccessLevel, this.user, this.team);
				}
			},
		},
		team: {
			immediate: true,
			handler (newVal: Team, oldVal: Team) {
				if (this.runnitState && newVal !== oldVal) {
					this.doRunnitsAndRunnitNodeDefsBindings(this.runnitState.runnitsOwnerSelection, this.runnitState.runnitsAccessLevel, this.user, newVal);
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'bindRunnits',
			'unbindRunnits',
			'bindPublicRunnitNodeDefs',
			'bindTeamRunnitNodeDefs',
			'bindRecentUserRunnitNodeDefInfos',
		]),
		doRunnitsAndRunnitNodeDefsBindings (runnitsOwnerSelection: RunnitsOwnerSelection, accessLevel: RunnitsAccessLevel, user: User, team: Team) {
			if (runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM && !team) {
				this.unbindRunnits();
			}
			this.bindRunnits({
				userId: user.id,
				teamId: runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM && team ? team.id : null,
				accessLevel,
			});
			this.bindPublicRunnitNodeDefs();
			this.bindTeamRunnitNodeDefs(team ? team.id : null);
			this.bindRecentUserRunnitNodeDefInfos(this.user);
		},
	},
});
