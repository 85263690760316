
import Vue, { isReadonly } from 'vue';
import { db } from '@/firebase';
import { mapActions, mapState } from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _keys from 'lodash/keys';
import _trim from 'lodash/trim';
import _union from 'lodash/union';
import _difference from 'lodash/difference';
import _omit from 'lodash/omit';
import { v4 as uuidv4 } from 'uuid';
import {
	FieldDefUuid,
	RunnitNodeFieldLogic,
	RunnitNodeDef,
	RunnitNodeDefFieldMapBuilderKey,
	RunnitNodeField,
	RunnitNodeFieldGroup,
	RunnitNodeStaticFields,
	RunnitPrivateNodeDef,
	RunnitPrivateNodeDefFieldMapBuilders,
	RunnitPrivateNodeDefFieldMappings,
	thousandthsToTokens,
	tokensToThousandths,
	RunnitPrivateNodeDefFieldValues,
	Team,
	Avatar,
	asyncForEach,
	RunnitPrivateFieldValueLogic,
} from '@run-diffusion/shared';
import { SNACKBAR_STATUS } from '@/constants/constants';
import {
	PROVIDER_ITEMS,
	NODE_TYPE_ITEMS,
	ENDPOINT_ID_ITEMS,
} from '@/views/Runnits/RunnitSettings/internalAdminOnly/constants';
import {
	AVATAR_USE_CASE,
	IMAGE_UPLOAD_MODE,
	RUNNIT_NODE_DEF_FIELD_MAP_BUILDER_VALUE_TYPE,
	RUNNIT_NODE_DEF_PROVIDER,
	RUNNIT_NODE_DEF_TOOL_TYPE,
	RUNNIT_NODE_FIELD_TYPE
} from '@/constants/enums';
import { get$bindFirestoreOptions } from '@/mixins';
import PurpleChip from '@/components/base/PurpleChip.vue';
import ActionsIsland from '@/components/ActionsIsland.vue';
import NumberField from '@/components/base/NumberField.vue';
import DialogContent from '@/components/base/DialogContent.vue';
import ClipboardCopy from '@/components/ClipboardCopy.vue';
import GreyButton from '@/components/base/GreyButton.vue';
import ImageUpload from '../ImageInput/ImageUpload.vue';
import RunnitImage from '../../RunnitImage.vue';
import { RUNNITS_OWNER_SELECTION } from '../../constants';

export default Vue.extend({
	name: 'RunnitDuplicateEditNodeDefInternalEditor',
	props: {
		value: { type: Boolean, default: false },
		maxWidth: { type: [Number, String], default: '1700px' },
		selectedNodeDef: { type: Object, default: null },
	},
	data () {
		const EDITOR_PAGES = {
			NODE_DEF: 'NODE_DEF',
			PRIVATE_NODE_DEF: 'PRIVATE_NODE_DEF',
		};
		const CAN_PICK_NUM_RESULTS = {
			N_A: null,
			YES: true,
			NO: false,
		};

		return {
			RUNNIT_NODE_DEF_TOOL_TYPE,
			EDITOR_PAGES,
			IMAGE_UPLOAD_MODE,
			AVATAR_USE_CASE,
			open: false,

			oldFields: null,
			newFields: null,

			privateOldFieldValues: null,
			privateNewFieldValues: null,
			privateOldFieldMappings: null,
			privateNewFieldMappings: null,
			privateOldFieldMapBuilders: null,
			privateNewFieldMapBuilders: null,

			editorPage: EDITOR_PAGES.NODE_DEF,

			privateNodeDef: null,
			loadingPrivateNodeDef: false,

			savingRunnitNodeDef: false,

			formValid: false,

			id: null,
			title: null,
			description: null,
			publishedAt: null,
			publishedAtSelected: false,
			imageUrl: null,
			avatarId: null,
			avatar: null,
			sortOrder: 0,
			costPerResult: 1000,
			providerItems: PROVIDER_ITEMS,
			provider: null,

			NODE_TYPE_ITEMS,
			type: null,
			teams: [],
			fetchingTeams: false,
			teamIdsArray: [],

			endpointIdsMap: {
				[RUNNIT_NODE_DEF_PROVIDER.RUNNIT_IMG]: ENDPOINT_ID_ITEMS[RUNNIT_NODE_DEF_PROVIDER.RUNNIT_IMG],
				[RUNNIT_NODE_DEF_PROVIDER.RD]: ENDPOINT_ID_ITEMS[RUNNIT_NODE_DEF_PROVIDER.RD],
				[RUNNIT_NODE_DEF_PROVIDER.OCTOAI]: ENDPOINT_ID_ITEMS[RUNNIT_NODE_DEF_PROVIDER.OCTOAI],
				[RUNNIT_NODE_DEF_PROVIDER.RUNPOD]: ENDPOINT_ID_ITEMS[RUNNIT_NODE_DEF_PROVIDER.RUNPOD],
				[RUNNIT_NODE_DEF_PROVIDER.IDEOGRAM]: ENDPOINT_ID_ITEMS[RUNNIT_NODE_DEF_PROVIDER.IDEOGRAM],
				[RUNNIT_NODE_DEF_PROVIDER.FALAI]: ENDPOINT_ID_ITEMS[RUNNIT_NODE_DEF_PROVIDER.FALAI],
				[RUNNIT_NODE_DEF_PROVIDER.NICHETENSOR]: ENDPOINT_ID_ITEMS[RUNNIT_NODE_DEF_PROVIDER.NICHETENSOR],
				[RUNNIT_NODE_DEF_PROVIDER.RUNWAY]: ENDPOINT_ID_ITEMS[RUNNIT_NODE_DEF_PROVIDER.RUNWAY],
				[RUNNIT_NODE_DEF_PROVIDER.LUMAAI]: ENDPOINT_ID_ITEMS[RUNNIT_NODE_DEF_PROVIDER.LUMAAI],
			},
			endpointId: null,
			coldStartWarningSeconds: null,

			CAN_PICK_NUM_RESULTS,
			canPickNumResults: CAN_PICK_NUM_RESULTS.YES,

			defaultNumResults: 1,
			minNumResults: 1,
			maxNumResults: 4,
			numResultsFieldMapping: null,

			isUploadingAvatar: false,
			isReadOnly: true,
		};
	},
	created () {
		this.fetchTeams();
	},
	watch: {
		value: {
			immediate: true,
			handler (newVal: boolean) {
				this.open = !!newVal;
			},
		},
		selectedNodeDef: {
			immediate: true,
			async handler (newVal: RunnitNodeDef, oldVal: RunnitNodeDef) {
				if (newVal !== oldVal) {
					this.isReadOnly = true;
					this.id = newVal?.id || null;
					this.title = newVal?.title || null;
					this.description = newVal?.description || null;
					this.imageUrl = newVal?.imageUrl || null; // deprecated
					this.avatarId = newVal?.avatarId || null;
					this.avatar = newVal?.avatar || null;
					this.sortOrder = newVal?.sortOrder || null;
					this.costPerResult = newVal?.costPerResult || 0;
					this.coldStartWarningSeconds = newVal?.coldStartWarningSeconds || null;
					this.type = newVal?.type || null;
					this.teamIdsArray = _keys(newVal?.teamIds || []).filter((teamId: string) => newVal.teamIds[teamId]);
					this.publishedAt = newVal?.publishedAt
						? new Date(newVal.publishedAt.toMillis())
						: null;
					this.publishedAtSelected = !_isEmpty(newVal?.publishedAt);
					this.deletedAt = newVal?.deletedAt || null;

					this.oldFields = JSON.stringify(newVal?.fields || {}, null, 2);
					this.newFields = this.oldFields;

					this.defaultNumResults = this._get(newVal, 'staticFields.numResults.defaultValue') || 1;
					this.minNumResults = this._get(newVal, 'staticFields.numResults.min') || 1;
					this.maxNumResults = this._get(newVal, 'staticFields.numResults.max') || 4;

					if (newVal) {
						await this.bindPrivateNodeDef(newVal);
					}
				}
			},
		},
		privateNodeDef: {
			immediate: true,
			async handler (newVal: RunnitPrivateNodeDef, oldVal: RunnitPrivateNodeDef) {
				if (newVal !== oldVal) {
					this.provider = newVal?.provider || null;
					this.endpointId = newVal?.endpointId || null;

					this.numResultsFieldMapping = this._get(newVal, 'staticFieldMappings.numResults.key') || null;
					this.canPickNumResults = this._get(newVal, 'staticFieldMappings.numResults')
						? (this._get(this.selectedNodeDef.staticFields, 'numResults') ? this.CAN_PICK_NUM_RESULTS.YES : this.CAN_PICK_NUM_RESULTS.NO)
						: this.CAN_PICK_NUM_RESULTS.N_A;
					this.defaultNumResults = this.canPickNumResults === this.CAN_PICK_NUM_RESULTS.NO
						? (this._get(newVal.staticFieldValues, 'numResults') || 1)
						: this.defaultNumResults;

					this.privateOldFieldValues = JSON.stringify(newVal?.fieldValues || {}, null, 2);
					this.privateNewFieldValues = this.privateOldFieldValues;
					this.privateOldFieldMappings = JSON.stringify(newVal?.fieldMappings || {}, null, 2);
					this.privateNewFieldMappings = this.privateOldFieldMappings;
					this.privateOldFieldMapBuilders = JSON.stringify(newVal?.fieldMapBuilders || {}, null, 2);
					this.privateNewFieldMapBuilders = this.privateOldFieldMapBuilders;
				}
			},
		},
		type: {
			immediate: true,
			handler (newVal) {
				if (newVal === RUNNIT_NODE_DEF_TOOL_TYPE.TEAM && this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM && this.team) {
					this.teamIdsArray = _union(this.teamIdsArray, [this.team.id]);
				}
			}
		}
	},
	computed: {
		...mapState([
			'team',
			'runnitState',
		]),
	},
	methods: {
		tokensToThousandths,
		_isEmpty,
		_trim,
		thousandthsToTokens,
		...mapActions([
			'updateSnackbar',
		]),
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
		},
		onNewFieldsInput (newFields: string) {
			this.newFields = newFields;
		},
		onPrivateNewFieldValuesInput (newFieldValues: string) {
			this.privateNewFieldValues = newFieldValues;
		},
		onPrivateNewFieldMappingsInput (newFieldMappings: string) {
			this.privateNewFieldMappings = newFieldMappings;
		},
		onPrivateNewFieldMapBuildersInput (newFieldMappings: string) {
			this.privateNewFieldMapBuilders = newFieldMappings;
		},
		checkFieldMappingsDisconnected (
			parsedNewFields: (RunnitNodeFieldGroup | RunnitNodeField)[],
			parsedNewFieldValues: RunnitPrivateNodeDefFieldValues,
			parsedNewFieldMappings: RunnitPrivateNodeDefFieldMappings,
			parsedNewFieldMapBuilders: RunnitPrivateNodeDefFieldMapBuilders,
		): string {
			const copyParsedNewFieldMappings: RunnitPrivateNodeDefFieldMappings = _cloneDeep(parsedNewFieldMappings || {});
			const copyParsedNewFieldMapBuilders: RunnitPrivateNodeDefFieldMapBuilders = _cloneDeep(parsedNewFieldMapBuilders || {});
			let unverifiedFieldDefUuid: FieldDefUuid = null;

			const checkAgainstFieldMapping: Function = (fieldDefUuid: FieldDefUuid): boolean => {
				if (copyParsedNewFieldMappings[fieldDefUuid]) {
					const builderKey: RunnitNodeDefFieldMapBuilderKey = this._get(copyParsedNewFieldMappings[fieldDefUuid], 'builderKey');
					if (builderKey && copyParsedNewFieldMapBuilders[builderKey]) {
						switch (copyParsedNewFieldMapBuilders[builderKey].type) {
							case RUNNIT_NODE_DEF_FIELD_MAP_BUILDER_VALUE_TYPE.STR_MUSTACHE:
								if (copyParsedNewFieldMapBuilders[builderKey].template.includes(fieldDefUuid)) {
									// consume the uuid to check against duplicate uuids (i.e. an issue)
									copyParsedNewFieldMapBuilders[builderKey].template = copyParsedNewFieldMapBuilders[builderKey].template.replace(fieldDefUuid, '');
									return false;
								}
								break;
							default:
								break;
						}
					} else if (!builderKey) {
						// consume the uuid to check against duplicate uuids (i.e. an issue)
						delete copyParsedNewFieldMappings[fieldDefUuid];
						return false;
					}
				}

				// check if the field is used to power a different field's logic
				const poweredFieldLogic = !!parsedNewFields.find((field: RunnitNodeFieldGroup | RunnitNodeField) => {
					if (field.logic) {
						return Object.keys(field.logic.field).includes(fieldDefUuid);
					}
					return false;
				});
				if (poweredFieldLogic) {
					delete copyParsedNewFieldMappings[fieldDefUuid];
					return false;
				}

				// check if the field is used to power a different field value's logic
				const poweredFieldValuesLogic = !!Object.values(parsedNewFieldValues).find((field: {
					value: any
					logic?: RunnitPrivateFieldValueLogic
				}) => {
					if (field.logic) {
						return Object.keys(field.logic.field).includes(fieldDefUuid);
					}
					return false;
				});
				if (poweredFieldValuesLogic) {
					delete copyParsedNewFieldMappings[fieldDefUuid];
					return false;
				}

				unverifiedFieldDefUuid = fieldDefUuid;
				return true; // short circuit
			};

			// Check parsedNewFields
			const verifyFieldsHasMapping: Function = (fields: (RunnitNodeField | RunnitNodeFieldGroup)[]): boolean => (
				(fields || []).some((groupOrField: RunnitNodeField | RunnitNodeFieldGroup) => {
					const group: RunnitNodeFieldGroup = groupOrField as RunnitNodeFieldGroup;
					const field: RunnitNodeField = groupOrField as RunnitNodeField;
					if (group.__rgroup) {
						return verifyFieldsHasMapping(group.fields);
					} else if (field.__rfield) {
						return checkAgainstFieldMapping(field.fieldDefUuid);
					}
				})
			);
			verifyFieldsHasMapping(parsedNewFields);

			// Check parsedNewFieldValues
			if (!unverifiedFieldDefUuid) {
				_keys(parsedNewFieldValues || {}).some((fieldDefUuid: FieldDefUuid) => (
					checkAgainstFieldMapping(fieldDefUuid)
				));
			}

			if (unverifiedFieldDefUuid) {
				// If the mapping key isn't defined as a field `fieldDefUuid` or set in `fieldValues`, then it should be removed
				return `The fieldDefUuid "${unverifiedFieldDefUuid}" is not found in "Field Mappings JSON" or is not correct in "Field Map Builders JSON"`;
			}

			return null;
		},
		async onSaveClick () {
			if (this.isReadOnly) return;
			if (!this.$refs.form.validate()) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Form invalid, check fields',
					show: true,
				});
				return;
			}

			/*
			JSON parsing error handling
			 */
			let parsedNewFields: (RunnitNodeFieldGroup | RunnitNodeField)[],
				parsedNewFieldValues: RunnitPrivateNodeDefFieldValues,
				parsedNewFieldMappings: RunnitPrivateNodeDefFieldMappings,
				parsedNewFieldMapBuilders: RunnitPrivateNodeDefFieldMapBuilders;
			try {
				parsedNewFields = JSON.parse(this.newFields || '{}');
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error parsing Fields JSON`,
					show: true,
				});
				return;
			}
			try {
				parsedNewFieldValues = JSON.parse(this.privateNewFieldValues || '{}');
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error parsing Field Values JSON`,
					show: true,
				});
				return;
			}
			try {
				parsedNewFieldMappings = JSON.parse(this.privateNewFieldMappings || '{}');
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error parsing Field Mappings JSON`,
					show: true,
				});
				return;
			}
			try {
				parsedNewFieldMapBuilders = JSON.parse(this.privateNewFieldMapBuilders || '{}');
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error parsing Field Mapping Builders JSON`,
					show: true,
				});
				return;
			}

			/*
			Fields syntax error handling
			 */
			const hasSyntaxErrorsInFields: Function = (fields: (RunnitNodeField | RunnitNodeFieldGroup)[]): boolean => (
				(fields || []).some((groupOrField: RunnitNodeField | RunnitNodeFieldGroup) => {
					const group: RunnitNodeFieldGroup = groupOrField as RunnitNodeFieldGroup;
					const field: RunnitNodeField = groupOrField as RunnitNodeField;
					return (
						!(group.__rgroup === true || field.__rfield === true) ||
						(group.__rgroup && hasSyntaxErrorsInFields(group.fields))
					);
				})
			);
			if (hasSyntaxErrorsInFields(parsedNewFields)) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Every object in "Fields JSON" must have \`"__rgroup": true\` or \`"__rfield": true\``,
					show: true,
				});
				return;
			}

			/*
			Field mappings error handling
			 */
			const fieldMappingsDisconnectedMessage: string = this.checkFieldMappingsDisconnected(
				parsedNewFields,
				parsedNewFieldValues,
				parsedNewFieldMappings,
				parsedNewFieldMapBuilders,
			);
			if (
				fieldMappingsDisconnectedMessage &&
				!confirm(`There is a disconnect with your field mappings. Message: ${fieldMappingsDisconnectedMessage}. Are you sure you want to save anyway?`)
			) {
				return;
			}

			try {
				this.savingRunnitNodeDef = true;

				await db.runTransaction(async (transaction) => {
					const isTeamNodeDef: boolean = this.type === RUNNIT_NODE_DEF_TOOL_TYPE.TEAM;

					// update runnitNodeDef
					const staticFields: RunnitNodeStaticFields = this.canPickNumResults === this.CAN_PICK_NUM_RESULTS.YES ? {
						numResults: {
							defaultValue: this.defaultNumResults,
							max: this.maxNumResults,
							min: this.minNumResults,
						},
					} : {};

					const teamIds: Record<string, true> = isTeamNodeDef ? this.teamIdsArray.reduce((map: Record<string, true>, teamId: string) => ({
						...map,
						[teamId]: true,
					}), {}) : null;
					transaction.update(
						db.doc(`runnitNodeDefs/${this.selectedNodeDef.id}`),
						{
							publishedAt: this.publishedAt,
							isPublished: !!this.publishedAt,
							title: this.title,
							description: this.description,
							imageUrl: this.imageUrl || null,
							avatarId: this.avatarId ? this.avatarId : null,
							avatar: this.avatarId ? db.doc(`avatars/${this.avatarId}`) : null,
							type: this.type,
							teamIds,
							sortOrder: this.sortOrder,
							costPerResult: Math.floor(this.costPerResult || 0),
							fields: parsedNewFields,
							staticFields,
							coldStartWarningSeconds: this.coldStartWarningSeconds || null,
						}
					)

					// update runnitPrivateNodeDef
					const staticFieldValues = this.canPickNumResults === this.CAN_PICK_NUM_RESULTS.NO && this.defaultNumResults > 1 ? {
						numResults: this.defaultNumResults,
					} : {};

					const staticFieldMappings = this.canPickNumResults !== this.CAN_PICK_NUM_RESULTS.N_A && this.numResultsFieldMapping ? {
						numResults: {
							key: _trim(this.numResultsFieldMapping),
							type: RUNNIT_NODE_FIELD_TYPE.NUM,
						},
					} : {};
					transaction.update(
						db.doc(`runnitPrivateNodeDefs/${this.selectedNodeDef.id}`),
						{
							provider: this.provider,
							endpointId: this.endpointId,
							fieldValues: parsedNewFieldValues,
							fieldMappings: parsedNewFieldMappings,
							fieldMapBuilders: parsedNewFieldMapBuilders,
							staticFieldValues,
							staticFieldMappings,
						}
					)

					// update teams to add the nodeDefId
					const oldTeamIdsArray: string[] = isTeamNodeDef
						? _keys(this.selectedNodeDef.teamIds || {}).filter((teamId: string) => this.selectedNodeDef.teamIds[teamId])
						: [];
					const omitTeamIdsArray: string[] = _difference(oldTeamIdsArray, this.teamIdsArray);
					await asyncForEach(omitTeamIdsArray, async (teamId: string) => {
						const teamRef = db.doc(`teams/${teamId}`);
						const teamSnapshot = await teamRef.get();
						const teamData: Team = teamSnapshot.data() as Team;

						transaction.update(
							db.doc(`teams/${teamId}`),
							{
								nodeDefIds: _omit(teamData.nodeDefIds || {}, this.selectedNodeDef.id),
							},
						);
					});
					await asyncForEach(this.teamIdsArray, async (teamId: string) => {
						const teamRef = db.doc(`teams/${teamId}`);
						const teamSnapshot = await teamRef.get();
						const teamData: Team = teamSnapshot.data() as Team;

						transaction.update(
							db.doc(`teams/${teamId}`),
							{
								nodeDefIds: {
									...teamData.nodeDefIds,
									[this.selectedNodeDef.id]: true,
								},
							},
						);
					});
				});

				// Reset JSON state values
				this.oldFields = JSON.stringify(parsedNewFields, null, 2);
				this.newFields = this.oldFields;
				this.privateOldFieldValues = JSON.stringify(parsedNewFieldValues, null, 2);
				this.privateNewFieldValues = this.privateOldFieldValues;
				this.privateOldFieldMappings = JSON.stringify(parsedNewFieldMappings, null, 2);
				this.privateNewFieldMappings = this.privateOldFieldMappings;
				this.privateOldFieldMapBuilders = JSON.stringify(parsedNewFieldMapBuilders, null, 2);
				this.privateNewFieldMapBuilders = this.privateOldFieldMapBuilders;

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! Tool settings saved`,
					show: true,
				});
				this.onCancel();
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error! Problem with saving tool settings, please try again`,
					show: true,
				});
			} finally {
				this.savingRunnitNodeDef = false;
			}
		},
		async bindPrivateNodeDef (nodeDef: RunnitNodeDef) {
			try {
				this.loadingPrivateNodeDef = true;
				const privateNodeDefRef = db.doc(`runnitPrivateNodeDefs/${nodeDef.id}`);
				await this.$bind(
					'privateNodeDef',
					privateNodeDefRef,
					get$bindFirestoreOptions(),
				);
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading privateNodeDef',
					show: true,
				});
			} finally {
				this.loadingPrivateNodeDef = false;
			}
		},
		async onDuplicate () {
			if (confirm('Are you sure you want to duplicate this tool?')) {
				try {
					this.savingRunnitNodeDef = true;

					// Reference the existing 'runnitNodeDefs' document
					const runnitNodeDefRef = db.doc(`runnitNodeDefs/${this.selectedNodeDef.id}`);
					const runnitPrivateNodeDefRef = db.doc(`runnitPrivateNodeDefs/${this.selectedNodeDef.id}`);

					/*
					GET DATA FOR DUPLICATING THE NODE DEF
					 */
					// Get the existing data from 'runnitNodeDefs'
					const docNodeDefSnapshot = await runnitNodeDefRef.get();
					if (!docNodeDefSnapshot.exists) {
						throw new Error("runnitNodeDefs document does not exist!");
					}
					const existingNodeDefData = docNodeDefSnapshot.data();

					// Modify the title to indicate it's a duplicated entry
					const duplicatedTitle = `${existingNodeDefData.title} Duplicated`;

					// Roll all the uuids in the NodeDef fields data to new uuids
					const oldToNewUuidMap: Record<string, string> = {};
					const rollUuidsInNodeFields: Function = (fields: (RunnitNodeField | RunnitNodeFieldGroup)[]): void => {
						(fields || []).forEach((groupOrField: RunnitNodeField | RunnitNodeFieldGroup) => {
							const group: RunnitNodeFieldGroup = groupOrField as RunnitNodeFieldGroup;
							const field: RunnitNodeField = groupOrField as RunnitNodeField;
							if (group.__rgroup) {
								rollUuidsInNodeFields(group.fields);
							} else if (field.__rfield) {
								if (!oldToNewUuidMap[field.uuid]) {
									oldToNewUuidMap[field.uuid] = uuidv4();
								}
								if (!oldToNewUuidMap[field.fieldDefUuid]) {
									oldToNewUuidMap[field.fieldDefUuid] = uuidv4();
								}
								field.uuid = oldToNewUuidMap[field.uuid];
								field.fieldDefUuid = oldToNewUuidMap[field.fieldDefUuid];
							}
						});
					};
					rollUuidsInNodeFields(existingNodeDefData.fields);

					// Roll all the fieldDefUuids in the NodeDef fields logic data to the newly generated fieldDefUuids
					const rollFieldDefUuidsInNodeFieldLogics: Function = (fields: (RunnitNodeField | RunnitNodeFieldGroup)[]): void => {
						(fields || []).forEach((groupOrField: RunnitNodeField | RunnitNodeFieldGroup) => {
							const group: RunnitNodeFieldGroup = groupOrField as RunnitNodeFieldGroup;
							const field: RunnitNodeField = groupOrField as RunnitNodeField;
							if (group.__rgroup) {
								rollFieldDefUuidsInNodeFieldLogics(group.fields);
								if (group.logic) {
									const clonedLogic: RunnitNodeFieldLogic = _cloneDeep(group.logic);
									group.logic.field = {};
									_keys(clonedLogic.field || {}).forEach((oldLogicFieldDefUuid: FieldDefUuid) => {
										group.logic.field[oldToNewUuidMap[oldLogicFieldDefUuid]] = clonedLogic[oldLogicFieldDefUuid] || {};
									});
								}
							} else if (field.__rfield) {
								if (field.logic) {
									const clonedLogic: RunnitNodeFieldLogic = _cloneDeep(field.logic);
									field.logic.field = {};
									_keys(clonedLogic.field || {}).forEach((oldLogicFieldDefUuid: FieldDefUuid) => {
										field.logic.field[oldToNewUuidMap[oldLogicFieldDefUuid]] = clonedLogic[oldLogicFieldDefUuid] || {};
									});
								}
							}
						});
					};
					rollFieldDefUuidsInNodeFieldLogics(existingNodeDefData.fields);

					/*
					GET DATA FOR DUPLICATING THE PRIVATE NODE DEF
					 */
					// Get the existing data from 'runnitPrivateNodeDefs'
					const docPrivateNodeDefSnapshot = await runnitPrivateNodeDefRef.get();
					if (!docPrivateNodeDefSnapshot.exists) {
						throw new Error("runnitPrivateNodeDefs document does not exist!");
					}
					const existingPrivateNodeDefData = docPrivateNodeDefSnapshot.data();

					// Roll all the uuids in the PrivateNodeDef field mappings and values data to new uuids
					const oldFieldMappings: RunnitPrivateNodeDefFieldMappings = existingPrivateNodeDefData.fieldMappings || {};
					const oldFieldValues: RunnitPrivateNodeDefFieldValues = existingPrivateNodeDefData.fieldValues || {};
					existingPrivateNodeDefData.fieldMappings = {};
					existingPrivateNodeDefData.fieldValues = {};
					_keys(oldFieldMappings).forEach((fieldDefUuid: FieldDefUuid) => {
						if (!oldToNewUuidMap[fieldDefUuid]) {
							oldToNewUuidMap[fieldDefUuid] = uuidv4();
						}
						existingPrivateNodeDefData.fieldMappings[oldToNewUuidMap[fieldDefUuid]] = _cloneDeep(oldFieldMappings[fieldDefUuid] || {});
					});
					_keys(oldFieldValues).forEach((fieldDefUuid: FieldDefUuid) => {
						if (!oldToNewUuidMap[fieldDefUuid]) {
							oldToNewUuidMap[fieldDefUuid] = uuidv4();
						}
						existingPrivateNodeDefData.fieldValues[oldToNewUuidMap[fieldDefUuid]] = _cloneDeep(oldFieldValues[fieldDefUuid]);

						const field = existingPrivateNodeDefData.fieldValues[oldToNewUuidMap[fieldDefUuid]];
						if (field.logic) {
							const clonedLogic: RunnitNodeFieldLogic = _cloneDeep(field.logic);
							field.logic.field = {};
							_keys(clonedLogic.field || {}).forEach((oldLogicFieldDefUuid: FieldDefUuid) => {
								field.logic.field[oldToNewUuidMap[oldLogicFieldDefUuid]] = clonedLogic.field[oldLogicFieldDefUuid] || {};
							});
						}
					});

					await db.runTransaction(async (transaction) => {
						const isTeamNodeDef: boolean = !!(
							existingNodeDefData.type === RUNNIT_NODE_DEF_TOOL_TYPE.TEAM &&
							this.team &&
							this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM
						);

						// Create a new document in 'runnitNodeDefs' with modified title
						const newRunnitNodeDefRef = db.collection('runnitNodeDefs').doc();
						transaction.set(newRunnitNodeDefRef, {
							...existingNodeDefData,
							createdAt: new Date(),
							publishedAt: null,
							isPublished: false,
							deletedAt: null,
							isDeleted: false,
							title: duplicatedTitle,  // Update the title in the new document
							teamIds: isTeamNodeDef ? { [this.team.id]: true } : null,
							...(!isTeamNodeDef && existingNodeDefData.type === RUNNIT_NODE_DEF_TOOL_TYPE.TEAM && {
								type: RUNNIT_NODE_DEF_TOOL_TYPE.CURATED,
							}),
						});

						if (isTeamNodeDef) {
							// update the team to include the new nodeDef
							const teamRef = db.doc(`teams/${this.team.id}`);
							const teamSnapshot = await teamRef.get();
							const teamData: Team = teamSnapshot.data() as Team;

							transaction.update(
								db.doc(`teams/${this.team.id}`),
								{
									nodeDefIds: {
										...teamData.nodeDefIds,
										[newRunnitNodeDefRef.id]: true,
									},
								},
							);
						}

						// Use the same data to create a new document in 'runnitPrivateNodeDefs'
						transaction.set(
							db.doc(`runnitPrivateNodeDefs/${newRunnitNodeDefRef.id}`),
							{
								...existingPrivateNodeDefData,
								createdAt: new Date(),
							},
						);
					});

					this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! Duplicate tool created`,
						show: true,
					});
					this.onCancel();
				} catch (e) {
					console.error(e);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: `Error! Problem with duplicating tool, please try again`,
						show: true,
					});
				} finally {
					this.savingRunnitNodeDef = false;
				}
			}
		},
		async onDelete () {
			if (this.readOnly) return;
			if (confirm('Are you sure you want to delete this tool?')) {
				try {
					this.savingRunnitNodeDef = true;

					// Reference the existing 'runnitNodeDefs' document
					const runnitNodeDefRef = db.doc(`runnitNodeDefs/${this.selectedNodeDef.id}`);

					// Soft Delete the document from 'runnitNodeDefs'
					await runnitNodeDefRef.update({
						deletedAt: new Date(),
						isDeleted: true,
					});

					this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! Tool deleted`,
						show: true,
					});
					this.onCancel();
				} catch (e) {
					console.error(e);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: `Error! Problem with deleting tool settings, please try again`,
						show: true,
					});
				} finally {
					this.savingRunnitNodeDef = false;
				}
			}
		},
		onPublishedAtChange (selected: boolean) {
			if (this.publishedAt) {
				if (confirm('Are you sure you want to unpublish this tool?')) {
					this.publishedAtSelected = selected;
					this.publishedAt = selected ? new Date() : null;
				}
			} else {
				this.publishedAtSelected = selected;
				this.publishedAt = selected ? new Date() : null;
			}
		},
		async fetchTeams () {
			try {
				this.fetchingTeams = true;
				// created index: teams	- isActive Ascending name Ascending __name__ Ascending
				const teamsRef = db.collection(`teams`)
					.where('isActive', '==', true)
					.orderBy('name', 'asc');

				(await teamsRef.get()).forEach(async (doc: any) => {
					const team: Team = {
						...doc.data(),
						get id () { return doc.id },
					} as Team;
					this.teams.push(team);
				});
			} catch (err) {
				console.error(err);
			} finally {
				this.fetchingTeams = false;
			}
		},
		handleAvatarUpload (avatar: Avatar) {
			if (this.isReadOnly) return;
			this.avatarId = avatar.id;
			this.avatar = avatar;
		},
		isCurrentTeam ({ id }) {
			return this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM && this.team && this.team.id === id;
		},
	},
	components: {
		GreyButton,
		ClipboardCopy,
		ActionsIsland,
		NumberField,
		PurpleChip,
		DialogContent,
		ImageUpload,
		RunnitImage,
	},
});
