import { render, staticRenderFns } from "./SessionBar.vue?vue&type=template&id=3f348d18&scoped=true&"
import script from "./SessionBar.vue?vue&type=script&lang=ts&"
export * from "./SessionBar.vue?vue&type=script&lang=ts&"
import style0 from "./SessionBar.vue?vue&type=style&index=0&id=3f348d18&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f348d18",
  null
  
)

export default component.exports