
import Vue from 'vue';
import { mapState } from 'vuex';
import {
	RunnitsCRUDMixin,
	RunnitsAndRunnitNodeDefsBindingsMixin,
} from '@/mixins';
import { RunnitNodeDef } from '../../../../shared/src';
import { RUNNIT_NODE_DEF_TOOL_TYPE, RUNNIT_TYPE } from '@/constants/enums';
import { FEATURED_RUNNIT_NODE_DEF_IDS, RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import OfferingCard from '@/components/OfferingCard.vue';

export default Vue.extend({
	name: 'RunnitToolsGallery',
	mixins: [
		RunnitsCRUDMixin,
		RunnitsAndRunnitNodeDefsBindingsMixin,
	],
	props: {},
	data () {
		return {
			RUNNITS_OWNER_SELECTION,
			RUNNIT_NODE_DEF_TOOL_TYPE,
			FEATURED_RUNNIT_NODE_DEF_IDS,
			RUNNIT_TYPE,
			coreTools: [],
			curatedTools: [],
			teamTools: [],
		};
	},
	watch: {
		publicRunnitNodeDefs: {
			immediate: true,
			handler (newVal: RunnitNodeDef[], oldVal: RunnitNodeDef[]) {
				this.coreTools = (newVal || []).filter((runnitNodeDef: RunnitNodeDef) => {
					return !!(
						runnitNodeDef.type === RUNNIT_NODE_DEF_TOOL_TYPE.CORE &&
						runnitNodeDef.publishedAt
					);
				});
				this.curatedTools = (newVal || []).filter((runnitNodeDef: RunnitNodeDef) => {
					return !!(
						runnitNodeDef.type === RUNNIT_NODE_DEF_TOOL_TYPE.CURATED &&
						runnitNodeDef.publishedAt
					);
				});
			}
		},
		teamRunnitNodeDefs: {
			immediate: true,
			handler (newVal: RunnitNodeDef[], oldVal: RunnitNodeDef[]) {
				this.teamTools = (newVal || []).filter((runnitNodeDef: RunnitNodeDef) => {
					return !!(
						runnitNodeDef.publishedAt
					);
				});
			}
		},
	},
	computed: {
		...mapState([
			'runnitState',
			'teamRunnitNodeDefs',
			'publicRunnitNodeDefs',
			'recentUserRunnitNodeDefInfos',
		]),
	},
	components: {
		OfferingCard,
	},
});
