
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { ROUTER } from '@/router/constants';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import EmptyState from '@/components/states/EmptyState.vue';
import RunnitAccountSelectionDialog from '@/views/Runnits/RunnitAccountSelectionDialog.vue';

export default Vue.extend({
	name: 'Runnits',
	created () {
		this.updateToolbar({
			insideRunnits: true,
		});
	},
	computed: {
		...mapState([
			'team',
			'runnitState',
		]),
		hideRouterViewForTeam () {
			return !!(
				this.$route.name !== ROUTER.RUNNITS_SUBSCRIPTIONS &&
				this.team &&
				this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM &&
				// DEPRECATED - Please stop supporting this soon
				!this._get(this.team, 'features.runnits')
			);
		},
		shouldRemoveSideMargins () {
			return [
				ROUTER.RUNNITS_BOARD,
			].includes(this.$route.name);
		},
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
		goToRunnitsSubscriptionsPage () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_SUBSCRIPTIONS });
		},
	},
	components: {
		RunnitAccountSelectionDialog,
		EmptyState,
	},
});
