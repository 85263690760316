var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnits-promo",style:({
		height: _vm.$vuetify.breakpoint.smAndDown ? '200px' : '300px',
	})},[_c('complex-background-big',{attrs:{"include-left-blobs":true}}),_c('div',{staticClass:"runnits-promo-cards-container"},[_c('div',{staticClass:"runnits-promo-cards",style:({
				transform: _vm.computedTransform,
			})},[_vm._l((_vm.runnits),function(runnit){return [(!runnit.avatar || typeof runnit.avatar !== 'string')?_c('offering-card',{key:runnit.id,staticClass:"mr-9",attrs:{"title":runnit.title,"image-url":runnit.src,"avatar":runnit.avatar,"description":runnit.description,"tool-type":runnit.appType,"display-only":""}}):_vm._e()]}),_c('div',{staticClass:"glass-square glass-square-1",class:{
					'dropInBottomAnimation': _vm.showAnimation,
				}}),_c('div',{staticClass:"glass-square glass-square-2",class:{
					'dropInTopAnimation': _vm.showAnimation,
				}}),_c('div',{staticClass:"glass-square glass-square-3",class:{
					'dropInTopAnimation': _vm.showAnimation,
				}}),_c('div',{staticClass:"glass-square glass-square-4",class:{
					'dropInBottomAnimation': _vm.showAnimation,
				}}),_c('div',{staticClass:"runnits-promo-grid",class:{
					'dropInBottomAnimation': _vm.showAnimation,
				}},_vm._l((_vm.numGridItems),function(i){return _c('div',{key:i,staticClass:"runnits-promo-grid-item"})}),0)],2)]),_c('div',{staticClass:"runnits-promo-text-container"},[_c('div',{staticClass:"runnits-promo-title",style:({
				...(_vm.$vuetify.breakpoint.xsOnly && {
					fontSize: '25px',
					margin: _vm.showButton ? '0 0 5px 0' : '30px 0 5px 0',
				}),
				...(_vm.$vuetify.breakpoint.smOnly && {
					fontSize: '25px',
					margin: '-10px 0 10px 0',
				}),
				...(_vm.$vuetify.breakpoint.mdAndUp && {
					fontSize: '40px',
					margin: '0 0 25px 0',
				}),
			})},[_vm._v(" Runnits "),_c('white-transparent-chip',{style:({
				margin: _vm.$vuetify.breakpoint.xsOnly ? '0 0 0 10px' : '6px 0 0 10px',
			})},[_vm._v(" beta ")])],1),_c('div',{staticClass:"runnits-promo-subtitle",style:({
				...(_vm.$vuetify.breakpoint.xsOnly && {
					fontSize: '12px',
					margin: '0 0 15px 0',
					padding: '0 40px 0 0',
				}),
				...(_vm.$vuetify.breakpoint.smOnly && {
					fontSize: '15px',
					margin: '0 0 15px 0',
				}),
				...(_vm.$vuetify.breakpoint.mdAndUp && {
					fontSize: '18px',
					margin: '0 0 40px 0',
				}),
			})},[_vm._v(" Create the runnit you need with the right tools ")]),(_vm.showButton)?_c('div',{staticClass:"runnits-promo-button"},[_c('base-button',{staticStyle:{"padding":"0 80px"},attrs:{"large":"","secondary-primary":""},on:{"click":_vm.goToRunnitsHome}},[_vm._v(" Go to Runnits ")])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }