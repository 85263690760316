
import Vue from 'vue';
import _sortBy from 'lodash/sortBy';
import { functions } from '@/firebase';
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { FEATURED_RUNNIT_NODE_DEF_IDS, RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import { RunnitNodeDef, RunnitNodeDefToolType } from '@run-diffusion/shared';
import { RUNNIT_NODE_DEF_TOOL_TYPE, RUNNIT_TYPE, RUNNITS_PUBLISHED_STATE, RunnitsPublishedState } from '@/constants/enums';
import OfferingCard from '@/components/OfferingCard.vue';
import GlassButton from '@/components/base/GlassButton.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import RunnitTypeMultiSelect from '@/views/Runnits/RunnitTypeMultiSelect.vue';
import ComplexBackground from '@/components/designElements/ComplexBackground.vue';
import EmptyStateCircleBackground from '@/components/states/EmptyStateCircleBackground.vue';
import RunnitDuplicateEditNodeDefInternalEditor from '@/views/Runnits/RunnitSettings/internalAdminOnly/RunnitDuplicateEditNodeDefInternalEditor.vue';
import { RunnitsCRUDMixin } from '@/mixins';
import { ROUTER } from '@/router/constants';
import DiagonalStackedSquaresSVG from '@/assets/DiagonalStackedSquaresSVG.vue';

export default Vue.extend({
	name: 'RunnitToolsLibraryDialog',
	props: {
		value: { type: Boolean, default: false },
		persistent: { type: Boolean, default: false },
		maxWidth: { type: [Number, String], default: '1100px' },
		singleToolMode: { type: Boolean, default: false },
	},
	mixins: [
		RunnitsCRUDMixin,
	],
	data () {
		return {
			RUNNITS_PUBLISHED_STATE,
			RUNNIT_NODE_DEF_TOOL_TYPE,
			FEATURED_RUNNIT_NODE_DEF_IDS,
			open: false,
			toolSearchValue: '',
			selectedTypes: [
				RUNNIT_NODE_DEF_TOOL_TYPE.CURATED,
				RUNNIT_NODE_DEF_TOOL_TYPE.CORE,
			],

			filteredRunnitNodeDefs: [],

			nodeDefInternalEditorDialog: {
				open: false,
				selectedNodeDef: null,
			},

			publishedStateFilter: RUNNITS_PUBLISHED_STATE.PUBLISHED,
			showRunnitUpsell: false,
		};
	},
	computed: {
		...mapState([
			'user',
			'runnits',
			'loadingRunnits',
			'loadingRecentUserRunnitNodeDefInfos',
			'recentUserRunnitNodeDefInfos',
			'runnitState',
			'publicRunnitNodeDefs',
			'loadingPublicRunnitNodeDefs',
			'teamRunnitNodeDefs',
			'loadingTeamRunnitNodeDefs',
		]),
	},
	watch: {
		value: {
			immediate: true,
			handler (newVal: boolean, oldVal: boolean) {
				this.open = !!newVal;
			},
		},
		publicRunnitNodeDefs: {
			immediate: true,
			handler (newVal: RunnitNodeDef[], oldVal: RunnitNodeDef[]) {
				this.filteredRunnitNodeDefs = [
					...(newVal || []),
				];
				this.applyFilters();
			}
		},
		open: {
			immediate: true,
			handler (newVal: boolean) {
				if (
					newVal && // the dialog is open
					!this.showRunnitUpsell && // the upsell is not already open
					this.singleToolMode && // not adding a tool to an existing runnit
					!this.runnits.length && // the user does not already have a runnit
					this.loadingRunnits === false && // done loading runnits
					this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.USER && // on personal runnits view, not team
					this.loadingRecentUserRunnitNodeDefInfos === false && // done loading recents
					this.recentUserRunnitNodeDefInfos.length >= 3 // the user has opened at least 3 tools
				) {
					setTimeout(() => {
						this.showRunnitUpsell = true;
					}, 1000);
				}
			}
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
			this.toolSearchValue = '';
		},
		onSelectionChosen () {
			this.onCancel();
		},
		handleMultiSelectFilter (selectedTypes: RunnitNodeDefToolType[]) {
			this.selectedTypes = selectedTypes;
			this.applyFilters();
		},
		handleSearchInput (searchValue) {
			this.toolSearchValue = searchValue;
			this.applyFilters();
		},
		handlePublishedFilterChange (publishedState: RunnitsPublishedState) {
			this.publishedStateFilter = publishedState;
			this.applyFilters();
		},
		applyFilters () {
			this.filteredRunnitNodeDefs = [];

			[
				...this.publicRunnitNodeDefs,
				...this.teamRunnitNodeDefs,
			].forEach((runnitNodeDef: RunnitNodeDef) => {
				let matchesSearch: boolean, matchesPublishedState: boolean, matchesSelectedTypes: boolean;

				if (this.toolSearchValue) {
					matchesSearch = runnitNodeDef.title.toLowerCase().includes(this.toolSearchValue.toLowerCase());
				} else {
					matchesSearch = true;
				}

				if (this.publishedStateFilter === RUNNITS_PUBLISHED_STATE.PUBLISHED) {
					matchesPublishedState = !!runnitNodeDef.publishedAt;
				} else if (this.publishedStateFilter === RUNNITS_PUBLISHED_STATE.NOT_PUBLISHED) {
					matchesPublishedState = !runnitNodeDef.publishedAt;
				} else {
					matchesPublishedState = false;
				}

				if (this.selectedTypes && this.selectedTypes.length) {
					matchesSelectedTypes = this.selectedTypes.includes(runnitNodeDef.type);
				} else {
					matchesSelectedTypes = false;
				}

				if (matchesSearch && matchesPublishedState && matchesSelectedTypes) {
					this.filteredRunnitNodeDefs.push(runnitNodeDef);
				}
			});

			this.filteredRunnitNodeDefs = _sortBy(this.filteredRunnitNodeDefs, 'sortOrder');
		},
		async onToolCardClick (runnitNodeDef: RunnitNodeDef) {
			if (this.singleToolMode) {
				await this.onAddRunnitClick(runnitNodeDef, RUNNIT_TYPE.SINGLE_TOOL);
				this.onCancel();
				return;
			}
			const onError: Function = (e) => {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error adding a tool to this runnit, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			};
			try {
				this.onCancel();
				this.$emit('on-adding-tool-loading', true);
				const functionRef = functions
					.httpsCallable('addNodeToRunnit');
				const { success } = (await functionRef({
					runnitId: this.$route.params.runnitId,
					nodeDefId: runnitNodeDef.id,
				})).data;
				if (!success) {
					onError(new Error('addNodeToRunnit returned success: false'));
				}
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: 'New tool added to runnit!',
					show: true,
				});
			} catch (e) {
				onError(e);
			} finally {
				this.$emit('on-adding-tool-loading', false);
			}
		},
		toggleAdminNodeDefEditor (selectedNodeDef: RunnitNodeDef) {
			this.nodeDefInternalEditorDialog = {
				...this.nodeDefInternalEditorDialog,
				open: true,
				selectedNodeDef,
			};
		},
		onEditDialogClose (open: boolean) {
			this.nodeDefInternalEditorDialog = {
				...this.nodeDefInternalEditorDialog,
				open: !!open,
			};
			this.applyFilters();
		},
		routeToPrivateRunnits () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_PRIVATE });
			this.setOpen(false);
		}
	},
	components: {
		GlassButton,
		LoadingState,
		OfferingCard,
		ComplexBackground,
		RunnitTypeMultiSelect,
		EmptyStateCircleBackground,
		RunnitDuplicateEditNodeDefInternalEditor,
		DiagonalStackedSquaresSVG,
	},
});
