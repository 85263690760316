
import Vue from 'vue';
import { RUNNIT_NODE_FIELD_TYPE } from '@/constants/enums';
import Prompt from '@/views/Runnits/RunnitSettings/Prompt.vue';
import WidthHeightField from '@/views/Runnits/RunnitSettings/WidthHeightField.vue';
import ImageInput from '@/views/Runnits/RunnitSettings/ImageInput/ImageInput.vue';
import NegativePrompt from '@/views/Runnits/RunnitSettings/NegativePrompt.vue';
import RunnitFieldHeader from '@/views/Runnits/RunnitSettings/RunnitFieldHeader.vue';
import CfgSlider from '@/views/Runnits/RunnitSettings/CfgSlider.vue';
import StepsSlider from '@/views/Runnits/RunnitSettings/StepsSlider.vue';
import SeedInput from '@/views/Runnits/RunnitSettings/SeedInput.vue';
import RunnitTextField from '@/views/Runnits/RunnitSettings/RunnitTextField.vue';
import RunnitBooleanField from '@/views/Runnits/RunnitSettings/RunnitBooleanField.vue';
import RunnitSingleSelect from '@/views/Runnits/RunnitSettings/RunnitSingleSelect.vue';
import RunnitBaseSlider from '@/views/Runnits/RunnitSettings/RunnitBaseSlider.vue';
import RunnitModelSelector from '@/views/Runnits/RunnitSettings/RunnitModelSelector.vue';
import {
	getLogicOnFieldsFromField,
	RunnitNodeField,
} from '@run-diffusion/shared';
import _keys from 'lodash/keys';
import _isArray from 'lodash/isArray';
import ListItem from '@/components/base/ListItem.vue';

export default Vue.extend({
	name: 'RunnitDynamicField',
	props: {
		field: { type: Object, required: true },
		value: { type: [Object, String, Number, Boolean], default: null },
		index: { type: [String, Number], default: null },
		nodeId: { type: String, default: null },
		inputValues: { type: Object, required: true },
	},
	data () {
		return {
			RUNNIT_NODE_FIELD_TYPE,
		};
	},
	computed: {
		isFirstIndex () {
			return +this.index === 0;
		},
		logicOnFields () {
			return getLogicOnFieldsFromField(this.field, this.inputValues);
		},
		isShown () {
			return !!(
				this.field &&
				(this.field.__rfield || this.field.__rgroup) &&
				(!this.logicOnFields.allShows.length || this.logicOnFields.triggeredShows.length)
			);
		},
		isDisabled () {
			return !!(
				this.isShown &&
				this.logicOnFields.triggeredDisables.length
			);
		},
		isDisabledBooleanFieldDesign () {
			return !!(
				this.isDisabled &&
				this.field.type === RUNNIT_NODE_FIELD_TYPE.BOOLEAN
			);
		},
		isDisabledOverlayDesign () {
			return !!(
				this.isDisabled &&
				!this.isDisabledBooleanFieldDesign
			);
		},
	},
	methods: {
		onFieldInput (field: RunnitNodeField, val: any) {
			this.$emit('input', field, val);
		},
	},
	components: {
		RunnitModelSelector,
		RunnitBaseSlider,
		RunnitSingleSelect,
		RunnitBooleanField,
		RunnitTextField,
		SeedInput,
		StepsSlider,
		CfgSlider,
		Prompt,
		ImageInput,
		WidthHeightField,
		NegativePrompt,
		RunnitFieldHeader,
		ListItem,
	},
});
