import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"offering-card",class:{
			'offering-card-disabled-hover': hover && _vm.disabled,
			'offering-card-hover': hover && !_vm.displayOnly && !_vm.disabled,
			'vertical-orientation': _vm.vertical,
			'horizontal-orientation': _vm.horizontal,
		},style:({
			..._vm.offeringCardStyles,
			...(_vm.$vuetify.breakpoint.xsOnly && {
				width: '100%',
			}),
			...(_vm.$vuetify.breakpoint.smAndUp && {
				width: `${_vm.computedContainerWidth}px`,
			}),
			height: `${_vm.computedContainerHeight}px`,
		}),on:{"click":function($event){_vm.displayOnly || _vm.disabled ? () => { } : _vm.$emit('click')}}},[_c('div',{staticClass:"offering-top-section",style:({
				justifyContent: 'center',
				...(_vm.vertical && {
					alignItems: 'flex-start',
					padding: '20px 5px 0 5px',
					...(_vm.small && {
						padding: '5px 0 0 0',
					}),
				}),
				...(_vm.horizontal && {
					alignItems: 'flex-start',
					padding: '15px 0 15px 25px',
					...(_vm.small && {
						padding: '15px 0 5px 15px',
					}),
				}),
			})},[_c('div',{staticClass:"offering-image-container"},[(!_vm.publishedAt && _vm.showNotPublished)?_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('glass-button',_vm._g(_vm._b({attrs:{"small":"","styles":{
								padding: '6px',
								borderRadius: '10px',
								position: 'absolute',
								top: '5px',
								right: '5px',
							}}},'glass-button',attrs,false),on),[_c(VIcon,{staticClass:"deep-orange--text",staticStyle:{"font-size":"20px"}},[_vm._v(" mdi-earth-off ")])],1)]}}],null,true)},[_c('div',{staticClass:"tooltip-max-width"},[_c('span',{staticStyle:{"color":"var(--grey-600)"}},[_vm._v("Published")]),_c('br'),_c('b',[_vm._v(_vm._s(_vm._f("moment")(_vm.publishedAt && _vm.publishedAt.toMillis(),'MMM D, YYYY [@] hh:mm a')))])])]):_vm._e(),_c('div',{staticClass:"offering-image-img-wrapper",style:({
						width: `${_vm.computedImageHeightWidth}px`,
						height: `${_vm.computedImageHeightWidth}px`,
					})},[(!(_vm.avatar || _vm.imageUrl))?_c('div',{staticClass:"darken-on-hover empty-image full-height full-width"},[_vm._v(" No Image ")]):(_vm.avatar)?_c('div',[(typeof _vm.avatar !== 'string')?_c('runnit-image',{staticClass:"darken-on-hover",attrs:{"avatar":_vm.avatar,"is-avatar":"","no-click":_vm.displayOnly || _vm.disabled}}):_c(VProgressCircular,{attrs:{"indeterminate":"","color":"#757575"}})],1):_c('img',{staticClass:"darken-on-hover offering-image",style:({
							width: `${_vm.computedImageHeightWidth}px`,
							height: `${_vm.computedImageHeightWidth}px`,
						}),attrs:{"src":_vm.imageUrl,"alt":"Offering Image"}}),(!_vm.displayOnly)?_c('div',{staticClass:"offering-image-hover-blur",style:({
							width: `${_vm.computedImageHeightWidth}px`,
							height: `${_vm.computedImageHeightWidth}px`,
						})},[_c('div',{staticClass:"offering-image-hover-blur-inner",style:({
								opacity: hover ? 1 : 0,
							})},[(_vm.disabled)?_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c(VIcon,{staticStyle:{"color":"var(--red-400)"},style:({
										...(_vm.disabledCtaText ? {
											fontSize: _vm.small ? '40px' : '55px',
										} : {
											fontSize: _vm.small ? '50px' : '75px',
										}),
									})},[_vm._v("mdi-lock-outline")]),(_vm.disabledText)?_c('b',{staticStyle:{"color":"var(--red-400)"}},[_vm._v(_vm._s(_vm.disabledText))]):_vm._e(),(_vm.disabledCtaText)?_c('base-button',_vm._b({staticClass:"mt-4",on:{"click":function($event){return _vm.$emit('disabled-cta-click')}}},'base-button',{
										..._vm.disabledCtaProps,
									},false),[_vm._v(" "+_vm._s(_vm.disabledCtaText)+" ")]):_vm._e()],1):_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c(VIcon,{staticClass:"gradient-text-90",style:({
										fontSize: _vm.small ? '50px' : '75px',
									})},[_vm._v(_vm._s(_vm.selectIcon))]),(_vm.selectText)?_c('b',{staticClass:"gradient-text-90"},[_vm._v(_vm._s(_vm.selectText))]):_vm._e()],1)])]):_vm._e()])],1)]),_c('div',{staticClass:"darken-on-hover offering-bottom-section",style:({
				...(_vm.vertical && {
					padding: '35px 25px 0 25px',
					...(_vm.small && {
						padding: '0 25px',
					}),
				}),
				...(_vm.horizontal && {
					padding: '0 35px',
					...(_vm.small && {
						padding: '10px 20px 0 20px',
					}),
				}),
			})},[_c('div',{staticClass:"offering-title offering-title-hover",class:{
					...(!(hover && _vm.disabled) && {
						'offering-title-team': _vm.toolType === _vm.RUNNIT_NODE_DEF_TOOL_TYPE.TEAM && !_vm.isFeatured,
						'gradient-text': _vm.isFeatured,
					}),
				},style:({
					...(_vm.vertical && {
						fontSize: '24px',
						margin: '0 0 15px 0',
						...(_vm.small && {
							fontSize: '16px',
							margin: '15px 0 15px 0',
						}),
					}),
					...(_vm.horizontal && {
						fontSize: '20px',
						margin: '15px 0 15px 0',
						...(_vm.small && {
							fontSize: '15px',
							margin: '5px 0 5px 0',
						}),
					}),
				})},[_c('div',[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.title,{ length: 18 }))+" ")]),(_vm.isFeatured)?_c(VIcon,{staticClass:"gradient-text-90"},[_vm._v(" mdi-star-four-points-circle ")]):_vm._e()],1),(_vm.description)?_c('div',{staticClass:"offering-description",style:({
					..._vm.descriptionStyles,
					...(!_vm.small && {
						fontSize: '14px',
					}),
					...(_vm.small && {
						fontSize: '13px',
					}),
				})},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.description,{ length: _vm.computedDescriptionCharLimit }))+" ")]):_vm._e()]),_c(VSlideYReverseTransition,[(_vm.showEditBtn && hover)?_c('div',{staticStyle:{"z-index":"10","position":"absolute","top":"10px","right":"10px"}},[_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('base-button',_vm._g(_vm._b({attrs:{"fab":"","small":"","white":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edit-click')}}},'base-button',attrs,false),on),[_c(VIcon,[_vm._v("mdi-cog-outline")])],1)]}}],null,true)},[(_vm.editText)?_c('div',{staticClass:"tooltip-max-width"},[_vm._v(_vm._s(_vm.editText))]):_vm._e()])],1):_vm._e()])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }